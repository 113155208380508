import React , {useEffect,useState} from 'react';
//import TweetEmbed from 'react-tweet-embed';


import Grid from '@mui/material/Grid';

import { connect } from 'react-redux';

import { get_twitter_data } from '../actions/twitter';


import Skeleton from '@mui/material/Skeleton';

import Button from '@mui/material/Button';


const Twitter = ({twitter_data,get_twitter_data}) => {

useEffect( () => {

get_twitter_data();


},[]);

const tweetPerRow = 7;


const [next, setNext] = useState(tweetPerRow);
const handleMoreTweet = () => {
    setNext(next + tweetPerRow);
  };

    return (
    <>

  <div className="gap-y-4 flex flex-wrap justify-center">
        {twitter_data?.slice(0, next)?.map((tweet, index) => {
          return (
            <div
              key={index}
              className="px-2.5 md:px-0"
            >
               {/* <TweetEmbed tweetId={tweet.twitter_id} options={{theme: 'dark'}} onTweetLoadSuccess={console.log('ok')} placeholder={'loading'} onLoad={console.log('ok')} /> */}

            </div>
          );
        })}
       {next < twitter_data?.length && (
          <Button
            className="mt-4"
            onClick={handleMoreTweet}
          >
            Load more
          </Button>
        )}
      </div>















    </>

    )


};


const mapStateToProps = state => ({
    twitter_data: state.twitter.twitter_data


});

export default connect(mapStateToProps,{get_twitter_data})(Twitter);

