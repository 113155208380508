import React ,{useState, useEffect} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import GroupsIcon from '@mui/icons-material/Groups';
import UploadIcon from '@mui/icons-material/Upload';
import NewspaperIcon from '@mui/icons-material/Newspaper';

import DownloadIcon from '@mui/icons-material/Download';
import { connect } from 'react-redux';

import MeetingUtility from './MeetingUtility';
import Upload from './Upload';
import News from './News';

import { update_active_dashboard } from '../actions/meeting_utility';

import { news_toggle_value } from '../actions/news';

import { useHistory } from "react-router-dom";

import Holding from './Holding';

const drawerWidth =150;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
    backgroundColor: "grey"
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 1,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const SideMenu = ({update_active_dashboard,active_dashboard,news_toggle_value}) => {

useEffect( () => {

if(active_dashboard == 'news'){

news_toggle_value('company')

}

},[active_dashboard]);


  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  const updateDashboard = (value) => {

    update_active_dashboard(value);

  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
           {/* <MenuIcon /> */}
          </IconButton>
          <Typography sx={{ 'flexGrow': '1', 'textAlign': 'center'}} variant="h6" noWrap component="div">
            SimplyIR Admin
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer   variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>

        <Divider />

           { active_dashboard == 'holding' ?
         <List  sx={{'backgroundColor':'#F7CD7B'}}>
         <ListItem  onClick={()=> updateDashboard('holding')} button key={'Holding'}>
              <ListItemIcon>
                 <DownloadIcon />
              </ListItemIcon>
              <ListItemText primary={'Holding'} />
        </ListItem>


        </List>
         :
         <List >
         <ListItem  onClick={()=> updateDashboard('holding')} button key={'Holding'}>
              <ListItemIcon>
                 <DownloadIcon />
              </ListItemIcon>
              <ListItemText primary={'Holding'} />
        </ListItem>



        </List>
         }
        <Divider />
        { active_dashboard == 'meeting' ?
         <List  sx={{'backgroundColor':'#F7CD7B'}}>
         <ListItem  onClick={()=> updateDashboard('meeting')} button key={'Meeting'}>
              <ListItemIcon>
                 <GroupsIcon />
              </ListItemIcon>
              <ListItemText primary={'Meeting'} />
        </ListItem>


        </List>
         :
         <List >
         <ListItem  onClick={()=> updateDashboard('meeting')} button key={'Meeting'}>
              <ListItemIcon>
                 <GroupsIcon />
              </ListItemIcon>
              <ListItemText primary={'Meeting'} />
        </ListItem>



        </List>
         }


        <Divider />

         { active_dashboard == 'upload' ?
         <List  sx={{'backgroundColor':'#F7CD7B'}}>
         <ListItem  onClick={()=> updateDashboard('upload')} button key={'Upload'}>
              <ListItemIcon>
                 <UploadIcon />
              </ListItemIcon>
              <ListItemText primary={'Upload'} />
        </ListItem>


        </List>
         :
         <List >
         <ListItem  onClick={()=> updateDashboard('upload')} button key={'Upload'}>
              <ListItemIcon>
                 <UploadIcon />
              </ListItemIcon>
              <ListItemText primary={'Upload'} />
        </ListItem>



        </List>
         }






        <Divider />

         { active_dashboard == 'news' ?
         <List  sx={{'backgroundColor':'#F7CD7B'}}>
         <ListItem  onClick={()=> updateDashboard('news')} button key={'News'}>
              <ListItemIcon>
                 <NewspaperIcon />
              </ListItemIcon>
              <ListItemText primary={'News'} />
        </ListItem>


        </List>
         :
         <List >
         <ListItem  onClick={()=> updateDashboard('news')} button key={'News'}>
              <ListItemIcon>
                 <NewspaperIcon />
              </ListItemIcon>
              <ListItemText primary={'news'} />
        </ListItem>



        </List>
         }

         <Divider />





      </Drawer>
       { active_dashboard == 'meeting' ?
      <Box component="main" sx={{ flexGrow: 1, p: 3,marginTop:'-90px',zIndex: 1251 }}>
        <DrawerHeader />
       <MeetingUtility />
      </Box>
      :
      null}

      { active_dashboard == 'upload' ?
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
       <Upload />
      </Box>
      :
      null
      }

      { active_dashboard == 'news' ?
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
       <News />
      </Box>
      :
      null
      }


      { active_dashboard == 'holding' ?
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Holding />
      </Box>
      :
      null
      }

    </Box>
  );
}

const mapStateToProps = state => ({
    active_dashboard: state.meeting_utility.active_dashboard,



});



export default connect(mapStateToProps,{update_active_dashboard,news_toggle_value})(SideMenu);
