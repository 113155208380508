import React , {useEffect,useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { connect } from 'react-redux';

import ArticleIcon from '@mui/icons-material/Article';

import { update_meeting_load } from '../../actions/meeting_utility';

import { create_meeting_ppt } from '../../actions/meeting_utility';

import { update_meeting_ppt } from '../../actions/meeting_utility';

import CircularProgress from '@mui/material/CircularProgress';


const CreatePpt = ({meeting_load,update_meeting_load, create_meeting_ppt, meeting_ppt, update_meeting_ppt }) => {

var start_date = new Date();
start_date.setDate(start_date.getDate() - 5);

var end_date = new Date();
end_date.setDate(end_date.getDate() + 5);



const [start, setStart] = React.useState(start_date.toISOString().slice(0, 10));

const [end, setEnd] = React.useState(end_date.toISOString().slice(0, 10));

const [email, setEmail] = React.useState("");

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    update_meeting_ppt(null);
    update_meeting_load(null);
    setOpen(false);
  };

   const handleCreate = () => {
    update_meeting_ppt('load');
    create_meeting_ppt(start, end, email);
  };


 const handleChange1 = (event) => {

    setStart(event.target.value);
  };

  const handleChange2 = (event) => {
    setEnd(event.target.value);
  };

   const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };


  useEffect( () => {


if(meeting_load == 'success_ppt'){


update_meeting_load(null);

}


},[meeting_load]);

  return (
    <div>
     <Button  sx={{'marginLeft':'20px'}} variant="contained" onClick={handleClickOpen} endIcon={<ArticleIcon />}>
        Create Report
      </Button>

      <Dialog open={open} onClose={handleClose} >
        <DialogTitle>Meeting Report</DialogTitle>
      <DialogContent>
          <DialogContentText>

          </DialogContentText>

{ meeting_ppt  == 'load' ?
<>
<div >
  <p style={{'textAlign': 'center'}}>    <CircularProgress /> </p>
 </div>
      <p style={{'color':'grey'}}> <br /> <br /> Please Wait while your report is being created  </p>
</>

:


     <>

          {meeting_ppt ==null  ?



                              <>
                                <div style={{'display':'flex', 'marginTop':'25px'}} >

                              <p style={{'marginRight':'10px','marginTop':'7px'}}> Start Date </p>


                         <TextField
                            id="date"
                            label=""
                            type="date"

                            sx={{'width':'200px'}}
                            value={start}
                            onChange= {handleChange1}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />

                              </div>

                               <div style={{'display':'flex', 'marginTop':'25px'}} >

                              <p style={{'marginRight':'10px','marginTop':'7px'}}> End Date </p>


                         <TextField
                            id="date"
                            label=""
                            type="date"

                            sx={{'width':'200px'}}
                            defaultValue={end == null ? null : end }
                            onChange= {handleChange2}
                            InputLabelProps={{
                              shrink: true,
                            }}
                          />

                              </div>

                               <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label="Email Address (optional)"
                                type="email"
                                onChange={handleEmailChange}
                                fullWidth
                                variant="standard"
                              />
                              </>
            :

                                <>

                                {meeting_ppt=='error' ? <> <p style={{'color':'red'}}> Unexpected error occured </p> <p style={{'color':'grey', 'fontSize':'12px'}}> Please try again.  If the Problem persist, contact Admin </p> </> : <> <p style={{'color':'green','fontSize':'11px'}}> The report has been succesfully created </p> <p style={{'fontSize':'18px'}}>Please find the report at the following <a href={meeting_ppt} target="_blank" > link </a>  </p> <p style={{'color':'grey','fontSize':'11px'}}> You would have also received the link on your mail , if it was given in the previous step </p> </>}
                                </>

           }



        </>


         }


        </DialogContent>
        <DialogActions>
        {meeting_ppt == null ?
        <>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleCreate}>Create</Button>
        </>
          :
          <Button onClick={handleClose}>Close</Button>
          }
        </DialogActions>
      </Dialog>



    </div>


  );
}


const mapStateToProps = state => ({
    meeting_load: state.meeting_utility.meeting_load,
    meeting_ppt: state.meeting_utility.meeting_ppt



});


export default connect(mapStateToProps,{update_meeting_load, create_meeting_ppt, update_meeting_ppt})(CreatePpt);