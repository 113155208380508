import React, { useState,useEffect } from 'react';

import TextField from '@mui/material/TextField';

import {
  Container } from "@mui/material";

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { create_holding_report } from '../actions/holding';

import { set_holding_null } from '../actions/holding';

import { connect } from 'react-redux';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import LinearProgress from '@mui/material/LinearProgress';

import SettingsIcon from '@mui/icons-material/Settings';


import Box from '@mui/material/Box';

import SettingToggle from "../components/holding/SettingToggle";

import UserSetting from "../components/holding/UserSetting";
import CompanySetting from "../components/holding/CompanySetting";

import { get_holding_dropdown } from '../actions/holding';

import Autocomplete from '@mui/material/Autocomplete';

import Snackbar from '@mui/material/Snackbar';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';


import GetAppIcon from '@mui/icons-material/GetApp';


const Holding = ({create_holding_report,create_holding, set_holding_null,setting_toggle,get_holding_dropdown,holding_dropdown}) => {

useEffect( () => {

get_holding_dropdown();



},[]);




const [open1, setOpen1] = React.useState(false);

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    get_holding_dropdown();
    setOpen1(false);
  };


useEffect( () => {


if(create_holding.length > 0){
setLoad(false);
handleClickOpen()

}



},[create_holding]);

const [load, setLoad] = React.useState(false);

const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    set_holding_null();
    setOpen(false);

  };

const [user, setUser] = React.useState(null);



const onSubmit = (user) => {



    if(user != null){
    setLoad(true);

    create_holding_report(user);
    }

    else{
     handleClick('Please select company for which report has to be generated','error') ;
    }
  };

const [formData, setFormData] = useState({

        report_user: 'demo'
    });


const {  report_user } = formData;


const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

const handleChange9 = (value) => {
    setUser(value);
  };




const [open77, setOpen77] = React.useState(false);

const [msg77, setMsg77] = React.useState('');
const [type77, setType77] = React.useState('');

  const handleClick = (message,severity) => {
    setMsg77(message);
    setType77(severity);


    setOpen77(true);
  };

  const handleClose77 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen77(false);
  };

 const action77 = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose77}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose77}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );




//'align-items':'center','justify-content':'center',

    return (
    <>





      <div style={{
            'margin': '50px 0',
           'margin-bottom': '20px',
          'padding': '30px 50px',
          'text-align': 'center',
        'border-radius': '4px',}}>



      <div style={{'justifyContent':'center','marginLeft':'40px'}}>

      <h1 style={{'color':'blue'}}>Create MF MIS Report</h1>

      <Container>
      <Grid container >


          <Grid item xs={12}>
           <Button   variant="outlined" onClick={handleClickOpen1} endIcon={<SettingsIcon />}>
                Settings
           </Button>

           </Grid>









                  <Grid item xs={12}>

                  <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '10vh'}}>
    <Autocomplete
                  disablePortal
                  id="report_user"
                  getOptionLabel={option => option.company_name}
                  options={holding_dropdown}
                  sx={{ width: 300, justifyContent:'center' }}
                  renderInput={(params) => <TextField {...params} label="Company Name"  fullWidth />}
                  onChange= {(event,value)=> handleChange9(value) }
                  />
</div>




                  </Grid>







                    <Grid item xs={12}>
                    <Button style={{'margin-top':'25px'}} variant="contained" className='btn btn-primary' type='submit' onClick={() =>onSubmit(user)}>Create Report</Button>
                    </Grid>

                     </Grid>
    </Container>


</div>

</div>


{ load ==true ?
<div style={{'width':'40%','margin':'auto'}}>
      <LinearProgress />
  </div>
  : null }





 <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Creating Report Status</DialogTitle>
      <DialogContent>
          <DialogContentText>

{create_holding.length ==2 ?
  <>


            <p style={{'color':'green','fontSize':'11px','fontWeight':'bold'}}>



            Suuccefuly created report ! <br />

            </p>

            <ul>
                  <li >Download the file from following  <a href={create_holding[0]}>   link  </a>   <br/></li>
                  <br/>
                  <li>Click  <a href={create_holding[1]} target="_blank">   here  </a> to go to one drive directory where all reports are saved
                  <p style={{'fontSize':'11px'}}>
                          (All Reports are sorted in a descending order on the basis of creation time )<br/>
                      </p>

                  </li>

             </ul>




                <p style={{'color':'red','fontSize':'11px'}}>
               Report Details will only be displayed once.
             </p>
    </>
          :
          <p> Error in Creating Report </p>

     }
          </DialogContentText>
      </DialogContent>

      <DialogActions>

          <Button onClick={handleClose}>Close</Button>
      </DialogActions>

 </Dialog>



  <Dialog open={open1} onClose={handleClose1}>
        <DialogTitle>  Setting</DialogTitle>
        <DialogContent>
          <DialogContentText>
     Modify the Company and Peers associated with them which would be used to create report
          </DialogContentText>
          <SettingToggle />

          {setting_toggle == 'company' ?
         < UserSetting />

          :

         < CompanySetting />

          }
        </DialogContent>
        <DialogActions>

          <Button onClick={handleClose1}>Close</Button>
        </DialogActions>
      </Dialog>


      <Snackbar
        open={open77}
        autoHideDuration={6000}
        onClose={handleClose77}
        message="Please enter the Company for which report has to be generated"
        action={action77}
      >
 <Alert onClose={handleClose77} severity={type77} sx={{ width: '100%' }}>
         {msg77}
 </Alert>

  </ Snackbar>

    </>



    )





};



const mapStateToProps = state => ({
    create_holding: state.holding.create_holding,
    setting_toggle: state.holding.setting_toggle,
    holding_dropdown: state.holding.holding_dropdown


});


export default connect(mapStateToProps,{create_holding_report,set_holding_null,get_holding_dropdown})(Holding);



