import {
    CREATE_HOLDING_SUCCESS,
    CREATE_HOLDING_FAIL,
    HOLDING_NULL_SUCCESS,
    HOLDING_NULL_FAIL,

    SETTING_TOGGLE_SUCCESS,
    SETTING_TOGGLE_FAIL,

    USER_SETTING_SUCCESS,
    USER_SETTING_FAIL,
    LOAD_USERSETTING_SUCCESS,
    LOAD_USERSETTING_FAIL,
    NULL_USERSETTING_SUCCESS,
    NULL_USERSETTING_FAIL,


    USER_DROPDOWN_SUCCESS,
    USER_DROPDOWN_FAIL,

    COMPANY_SETTING_SUCCESS,
    COMPANY_SETTING_FAIL,
    LOAD_COMPANYSETTING_SUCCESS,
    LOAD_COMPANYSETTING_FAIL,
    NULL_COMPANYSETTING_SUCCESS,
    NULL_COMPANYSETTING_FAIL,

    HOLDING_DROPDOWN_SUCCESS,
    HOLDING_DROPDOWN_FAIL,

    HOLDING_LOAD_SUCCESS,
    HOLDING_LOAD_FAIL

} from '../actions/types';



const initialState = {
    create_holding:[],
    setting_toggle:'company',

    load_usersetting: null,


    user_dropdown : '',

    user_setting : [{'company_name':'Please Wait'}],

    company_setting: [],
    load_companysetting: null,

    holding_dropdown: [
  { label: 'Please Wait', year: 1994 }],

  master_dropdown: ["Please Wait"],

  meeting_load: null

};


export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case CREATE_HOLDING_SUCCESS:
            return {
                ...state,
                create_holding: payload

            }

        case CREATE_HOLDING_FAIL:
            return {
                ...state

            }

        case HOLDING_NULL_SUCCESS:
            return {
                ...state,
                create_holding: []

            }

        case HOLDING_NULL_FAIL:
            return {
                ...state

            }





        case SETTING_TOGGLE_SUCCESS:
            return {
                ...state,
                setting_toggle: payload

            }

        case SETTING_TOGGLE_FAIL:
            return {
                ...state

            }


        case USER_SETTING_SUCCESS:
            return {
                ...state,
                user_setting: payload

            }

        case USER_SETTING_FAIL:
            return {
                ...state

            }


        case LOAD_USERSETTING_SUCCESS:
            return {
                ...state,
               load_usersetting: payload

            }

        case LOAD_USERSETTING_FAIL:
            return {
                ...state

            }


        case NULL_USERSETTING_SUCCESS:
            return {
                ...state,
               load_usersetting: null

            }

        case NULL_USERSETTING_FAIL:
            return {
                ...state

            }





        case USER_DROPDOWN_SUCCESS:
            return {
                ...state,
                user_dropdown: payload

            }

        case USER_DROPDOWN_FAIL:
            return {
                ...state

            }

       case COMPANY_SETTING_SUCCESS:
            return {
                ...state,
                company_setting: payload

            }

        case COMPANY_SETTING_FAIL:
            return {
                ...state

            }


        case LOAD_COMPANYSETTING_SUCCESS:
            return {
                ...state,
               load_companysetting: payload

            }

        case LOAD_COMPANYSETTING_FAIL:
            return {
                ...state

            }


        case NULL_COMPANYSETTING_SUCCESS:
            return {
                ...state,
               load_companysetting: null

            }

        case NULL_COMPANYSETTING_FAIL:
            return {
                ...state

            }



        case HOLDING_DROPDOWN_SUCCESS:
            return {
                ...state,
                holding_dropdown: payload['company'],
                master_dropdown: payload['master']

            }

        case HOLDING_DROPDOWN_FAIL:
            return {
                ...state

            }



        case HOLDING_LOAD_SUCCESS:
            return {
                ...state,
                holding_load: payload


            }

        case HOLDING_LOAD_FAIL:
            return {
                ...state

            }








        default:
            return state


        }


    };

