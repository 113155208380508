import {
    UPLOAD_TABLE_SUCCESS,
    UPLOAD_TABLE_FAIL,
    SYNC_UPLOAD_SUCCESS,
    SYNC_UPLOAD_FAIL,
    UPLOAD_NULL_SUCCESS,
    UPLOAD_NULL_FAIL



} from '../actions/types';



const initialState = {
    upload_table: [],
    sync_status: 'neutral'


};


export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case UPLOAD_TABLE_SUCCESS:
            return {
                ...state,
                upload_table: payload

            }

        case UPLOAD_TABLE_FAIL:
            return {
                ...state

            }


        case SYNC_UPLOAD_SUCCESS:
            return {
                ...state,
                sync_status: 'success'

            }

        case SYNC_UPLOAD_FAIL:
            return {
                ...state,
                sync_status: 'fail'

            }


        case UPLOAD_NULL_SUCCESS:
            return {
                ...state,
                sync_status: payload

            }

        case UPLOAD_NULL_FAIL:
            return {
                ...state

            }






        default:
            return state


        }


    };

