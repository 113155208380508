import React from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { meeting_toggle_value } from '../../actions/meeting_utility';

import { connect } from 'react-redux';

import Button from '@mui/material/Button';



import Autocomplete from '@mui/material/Autocomplete';

import TextField from '@mui/material/TextField';

const MeetingToggle = ({meeting_toggle_value,meeting_toggle}) => {

const [alignment, setAlignment] = React.useState({ label: 'To be Filled', name: 'current' });

const handleChange = (value) => {
    setAlignment(value);
    meeting_toggle_value(value.name);
  };


  const top100Films =  [
  { label: 'To be Filled', name: 'current' },
  { label: 'Already Filled', name: 'history' }
  ];

    return (
    <>


   <div  style={{  'float':'right','marginRight':'15px','marginLeft':'auto','marginTop':'10px'}} >


          <Autocomplete
      disableClearable
      disablePortal
      id="combo-box-demo"
      value= {alignment}
      options={top100Films}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Filter" />}
      onChange= {(event,value)=> handleChange(value) }
    />
    </div>






    </>

    )


};


const mapStateToProps = state => ({
    meeting_toggle: state.meeting_utility.meeting_toggle,


});



export default connect(mapStateToProps,{meeting_toggle_value})(MeetingToggle);