import React from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { admin_toggle_value } from '../../actions/admin';

import { connect } from 'react-redux';

import { get_admin_dropdown } from '../../actions/admin';



const AdminToggle = ({admin_toggle_value,admin_toggle, get_admin_dropdown}) => {

const [alignment, setAlignment] = React.useState('one');

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);

    get_admin_dropdown();

    admin_toggle_value(newAlignment);




  };

    return (
    <>

<div  style={{  'float':'right','marginRight':'15px','marginLeft':'auto','marginTop':'-10px'}} >
    <ToggleButtonGroup
      value={admin_toggle}
      onChange={handleAlignment}
      color="secondary"

      exclusive


    >
      <ToggleButton  value="sector">Peer :</ToggleButton>

      <ToggleButton  value="company">Company</ToggleButton>



    </ToggleButtonGroup>
    </div>



    </>

    )


};


const mapStateToProps = state => ({
    admin_toggle: state.admin.admin_toggle,



});



export default connect(mapStateToProps,{admin_toggle_value,get_admin_dropdown})(AdminToggle);