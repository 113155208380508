import {
	makeApiRequest,
	makeApiRequest1,
    makeApiRequest2,
	generateSymbol,
	parseFullSymbol,
} from './helpers.js';

//import {symbol_master} from "./Array.js"
/*
import {
	subscribeOnStream,
	unsubscribeFromStream,
} from './streaming.js';
*/

const lastBarsCache = new Map();

var symbol_master= [];





const configurationData = {
	supported_resolutions: ['1D', '1W', '1M'],
	exchanges: [{
		value: 'Bitfinex',
		name: 'Bitfinex',
		desc: 'Bitfinex',
	},
	{
		// `exchange` argument for the `searchSymbols` method, if a user selects this exchange
		value: 'Kraken',

		// filter name
		name: 'Kraken',

		// full exchange name displayed in the filter popup
		desc: 'Kraken bitcoin exchange',
	},
	],
	symbols_types: [{
		name: 'crypto',

		// `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
		value: 'crypto',
	},
		// ...
	],
};

const configurationData1 = {
	supported_resolutions: ['1D', '1W', '1M'],


	symbols_types: [{
		name: 'stock',

		// `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
		value: 'stock',
	},
	{
		name: 'index',

		// `symbolType` argument for the `searchSymbols` method, if a user selects this symbol type
		value: 'index',
	},
		// ...
	],
};

async function getAllSymbols() {
	const data = await makeApiRequest('data/v3/all/exchanges');
	let allSymbols = [];

	for (const exchange of configurationData.exchanges) {
		const pairs = data.Data[exchange.value].pairs;

		for (const leftPairPart of Object.keys(pairs)) {
			const symbols = pairs[leftPairPart].map(rightPairPart => {
				const symbol = generateSymbol(exchange.value, leftPairPart, rightPairPart);
				return {
					symbol: symbol.short,
					full_name: symbol.full,
					description: symbol.short,
					exchange: exchange.value,
					type: 'crypto',
				};
			});
			allSymbols = [...allSymbols, ...symbols];
		}
	}
	return allSymbols;
}


async function getAllSymbols1() {
	const allSymbols = await makeApiRequest1('symbol-master');

	return allSymbols;
}

export default {



	onReady:   (callback) => {
		console.log('[onReady]: Method call');
		symbol_master =  getAllSymbols1();

		setTimeout(() => callback(configurationData1));
	},

	searchSymbols: async (
		userInput,
		exchange,
		symbolType,
		onResultReadyCallback,
	) => {
    const symbols = await symbol_master;


	 console.log('[searchSymbols]: Method call' );
		//const symbols = await getAllSymbols1();

		// const symbols = symbol_master;


		 const newSymbols =  symbols.filter(symbol => {
			const isExchangeValid = symbolType === '' || symbol.type === symbolType;
			const isFullSymbolContainsInput = symbol.description
				.toLowerCase()
				.indexOf(userInput.toLowerCase()) !== -1;

			const isSymbolContainsInput = symbol.symbol
				.toLowerCase()
				.indexOf(userInput.toLowerCase()) !== -1;

			return isExchangeValid && (isFullSymbolContainsInput || isSymbolContainsInput)  ;
		});

        onResultReadyCallback(newSymbols.slice(0,320));








	},

	resolveSymbol: async (
		symbolName,
		onSymbolResolvedCallback,
		onResolveErrorCallback,
	) => {
		console.log('[resolveSymbol]: Method call', symbolName);
		//const symbols = await getAllSymbols1();
		const symbols = await symbol_master;
		console.log(symbols);
		const symbolItem = symbols.find(({
			full_name,
		}) => full_name === symbolName);
		if (!symbolItem) {
			console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
			onResolveErrorCallback('cannot resolve symbol');
			return;
		}
		const symbolInfo = {
			ticker: symbolItem.full_name,
			name: symbolItem.symbol,
			description: symbolItem.description,
			fincode: symbolItem.fincode,
			type: symbolItem.type,
			session: '24x7',
			timezone: 'Etc/UTC',
			exchange: symbolItem.exchange,
			minmov: 1,
			pricescale: 100,
			has_intraday: false,
			has_no_volume: false,
			has_weekly_and_monthly: false,
			supported_resolutions: configurationData.supported_resolutions,
			volume_precision: 2,
			data_status: 'streaming',
		};

		console.log('[resolveSymbol]: Symbol resolved', symbolName);
		onSymbolResolvedCallback(symbolInfo);
	},

	getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
		const { from, to, firstDataRequest } = periodParams;
		console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
		// const parsedSymbol = parseFullSymbol(symbolInfo.full_name);

		const urlParameters = {
		    Type: 'H',
		    FINCODE: symbolInfo.fincode,
			STK: symbolInfo.exchange,
			DateOption: '',
			DateCount: 5,
			StartDate: '5/20/2015',
			EndDate: '8/20/2021',
			token: 'Ldz3VmZSf0Y9l4HKjRB_vn_wz0PnKu3k'
		};
		const query = Object.keys(urlParameters)
			.map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
			.join('&');





		try {
			const data = await makeApiRequest2(`https://transcriptanalyser.com/symbol-detail?${query}`);


			if (data.Response && data.Response === 'Error' || data.Data.length === 0) {
				// "noData" should be set if there is no data in the requested period.
				onHistoryCallback([], {
					noData: true,
				});
				return;
			}
			let bars = [];
			data.Data.forEach(bar => {
				if (bar.time >= from && bar.time < to) {
					bars = [...bars, {
						time: bar.time * 1000,
						low: bar.low,
						high: bar.high,
						open: bar.open,
						close: bar.close,
						volume: bar.volume/1000
					}];
				}
			});
			if (firstDataRequest) {
				lastBarsCache.set(symbolInfo.full_name, {
					...bars[bars.length - 1],
				});
			}
			console.log(`[getBars]: returned ${bars.length} bar(s)`);
			onHistoryCallback(bars, {
				noData: false,
			});
		} catch (error) {
			console.log('[getBars]: Get error', error);
			onErrorCallback(error);
		}
	},
/*
	subscribeBars: (
		symbolInfo,
		resolution,
		onRealtimeCallback,
		subscribeUID,
		onResetCacheNeededCallback,
	) => {
		console.log('[subscribeBars]: Method call with subscribeUID:', subscribeUID);
		subscribeOnStream(
			symbolInfo,
			resolution,
			onRealtimeCallback,
			subscribeUID,
			onResetCacheNeededCallback,
			lastBarsCache.get(symbolInfo.full_name),
		);
	},

	unsubscribeBars: (subscriberUID) => {
		console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
		unsubscribeFromStream(subscriberUID);
	},


*/
};