import React, {useState,useEffect } from 'react';



import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { connect } from 'react-redux';

import Button from '@mui/material/Button';

import { update_meeting_detail } from '../../actions/meeting_utility';

import { add_meeting_member } from '../../actions/meeting_utility';

import { delete_meeting_member } from '../../actions/meeting_utility';

import { update_meeting_load } from '../../actions/meeting_utility';

import { get_meeting_child } from '../../actions/meeting_utility';

import { get_meeting_master } from '../../actions/meeting_utility';

import { add_meeting_copy } from '../../actions/meeting_utility';



import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { Link, Redirect,NavLink } from 'react-router-dom';

import MaterialTable from "material-table";
//import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';


import InputMeeting from "./InputMeeting";

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Autocomplete from '@mui/material/Autocomplete';


import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import CircularProgress from '@mui/material/CircularProgress';

import Checkbox from '@mui/material/Checkbox';

import Snackbar from '@mui/material/Snackbar';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';



import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CurrentMeeting = ({current_meeting, update_meeting_detail,meeting_dropdown, add_meeting_member, update_meeting_load, meeting_load, get_meeting_child, meeting_child, delete_meeting_member, get_meeting_master, add_meeting_copy}) => {

const handleCopy = (data) => {
    setChecked7();
    add_meeting_copy(data[0]);

  };


const maxSelections = 1;

const [checked7, setChecked7] = useState();

const handleSelectionProps = rowData => {
    return {
      disabled:
        checked7 && checked7.length >= maxSelections && !rowData.tableData.checked
          ? true
          : false
    };
  };

const type_list = [
  { label: 'Individual Meeting', year: 1 },
  { label: 'Group Meeting', year: 2 }
  ];


const type_list9 = meeting_dropdown;

const type_list99 = [
  { label: 'Road Show', year: 1 },
  { label: 'Investor Day', year: 2 },
  { label: 'Site Visit', year: 3 },
  { label: 'Others', year: 4 },
  { label: 'Conference', year: 5 },
  ];

useEffect( () => {


if (current_meeting[0]['company_name'] != 'Please Wait....'){

setKey(current_meeting[0]['meeting_id'])

setPdf(current_meeting[0]['pdf_s3_url'])

setLink(current_meeting[0]['meeting_format'])

setLink1(current_meeting[0]['meeting_event_date'])

setComp(current_meeting[0]['s_name'])

setOrganiser(current_meeting[0]['meeting_organiser']);

setEvent(current_meeting[0]['event_type']);

}

},[current_meeting]);


useEffect( () => {

get_meeting_child();

},[]);

useEffect( () => {

if(meeting_load == 'success'){
get_meeting_child();
}

if(meeting_load == 'success_saved'){

get_meeting_master();

handleClick('Succesfully Saved the changes made','success');

update_meeting_load(null);


}


if(meeting_load == 'success_copy'){

get_meeting_child();


handleClick('Succesfully Copied the Rows','success');

update_meeting_load(null);

get_meeting_master();
}





},[meeting_load]);

const handleChange = (value) => {
    setLink(value.label);
  };

const handleChange9 = (value) => {

    setOrganiser(value.label);
  };


const handleChange99 = (value) => {

    setEvent(value.label);
  };

const [age, setAge] = React.useState('');

  const add_member = (dropdown, key) => {



    if(dropdown.label != null){
    update_meeting_load('load');
    add_meeting_member(dropdown.label,dropdown.FirmId, key);
    }
  };


    const delete_member = (firm_id, key) => {
    update_meeting_load('load');
    delete_meeting_member(firm_id, key);
  };


const [dropdown, setDropdown] = React.useState('');


const top100Films1 = [
  { label: 'The Shawshank Redemption', year: 1994 },
  { label: 'The Godfather', year: 1972 },
  { label: 'The Godfather: Part II', year: 1974 },
  { label: 'The Dark Knight', year: 2008 },
  { label: '12 Angry Men', year: 1957 },
  { label: "Schindler's List", year: 1993 }

  ];

const top100Films = meeting_dropdown;


 const fetch_data = async (filter_value) => {

 setDropdown(filter_value)

    };




const [open, setOpen] = React.useState(false);
const [key, setKey] = React.useState(0);
const [link, setLink] = React.useState(null);

const [link1, setLink1] = React.useState(null);

const [comp, setComp] = React.useState(null);

const [organiser, setOrganiser] = React.useState(null);

const [event, setEvent] = React.useState(null);

const [pdf, setPdf] = React.useState(null);

const [checked, setChecked] = React.useState(false);

  const handleClickOpen = (link,link1,key,comp_name,organiser,event,pdf) => {
    {/* setOpen(true); */}
    setKey(key);
    setLink(link);
    setLink1(link1);
    setComp(comp_name);
    setOrganiser(organiser);
    setEvent(event);
    setPdf(pdf);
  };

  const handleClose = () => {
    setOpen(false);
    {/* setKey(0); */}
  };

   const update_close = (value,value1,key,checked, organiser, event) => {


    if(value == null){
    if(value1==null){
    update_meeting_detail("","",key,checked,organiser,event);
    }

    else{
    update_meeting_detail("",value1,key,checked,organiser,event);
    }
    }

    else{
    if(value1==null){
    update_meeting_detail(value,"",key,checked,organiser,event);
    }

    else{
    update_meeting_detail(value,value1,key,checked,organiser,event);
    }

    }

    setOpen(false);
    {/* setKey(0); */}
  };




const rows = current_meeting;

const updateMeeting = (link, link1, key, comp_name, organiser,event,pdf) => {

    handleClickOpen(link,link1,key, comp_name, organiser, event,pdf);

  };


const handleChange1 = (event) => {
    setLink1(event.target.value);
  };

 const handleChange7 = (event) => {
   console.log(event.target.checked);
    setChecked(event.target.checked);
  };







const [open1, setOpen1] = React.useState(false);

const [msg, setMsg] = React.useState('');
const [type, setType] = React.useState('');

  const handleClick = (message,severity) => {
    setMsg(message);
    setType(severity);


    setOpen1(true);
  };

  const handleClose1 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen1(false);
  };

 const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose1}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose1}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


  return (
  <>
<Grid container spacing={2}>
  <Grid item xs={6}>

  {rows == null ? null :
<>







<MaterialTable


        size="small"
        onSelectionChange={rows => setChecked7(rows)}
         onRowClick={(event, rowData) => {

          updateMeeting(rowData.meeting_format,rowData.meeting_event_date,rowData.meeting_id,rowData.s_name, rowData.meeting_organiser, rowData.event_type ,rowData.pdf_s3_url);
        }}
          columns={[

        { title: "Newsdate", field: "Newsdate",cellStyle: {
        minWidth: 115
      } },



          { title: "company_name" ,cellStyle: {
  minWidth: 215
},
   render: rowData => <> {rowData['copy_id'] == null ? <span  > {rowData['company_name']} </span> : <span style={{'color':'blue'}}  > {rowData['company_name']} (copy) </span> } </>
 },


      { title: "Entity" ,cellStyle: {
  minWidth: 215
},
   render: rowData =>  <>

         {
     rowData['entity_name'].split("$").map(

           (content, i) =>{

           return(

           <>

           <span style={{'color':'blue'}}>

               {content.split("%")[0]}

            </span>

            <span style={{'color':'grey','fontSize':'9px'}}>


               {content.split("%").length == 1 ? '' : ' '+ content.split("%")[1]}

            </span>

            <br/>

            </>


           )

           }

)

}

   </>
 },



        { title: "Details", field: "Details" ,cellStyle: {
        minWidth: 415
      } },

       { title: "Heading", field: "Heading",cellStyle: {
        minWidth: 215
      } },




{/*

       { title: "pdf_link" ,cellStyle: {
  minWidth: 115
},
   render: rowData => <a href = {rowData['Attachmenturl']} target="_blank" > Link </a>
 },



{ title: "enter_detail",cellStyle: {
  minWidth: 115
},
   render: rowData =><> <Button onClick={()=>updateMeeting(rowData.meeting_format,rowData.meeting_event_date,rowData.meeting_id,rowData.s_name, rowData.meeting_organiser)}  size="small"  variant="outlined"> <span style={{'color':'green'}} >Enter Detail </span></Button>   </>
 },


 */}








          ]}
          data={current_meeting}
          title="Who is Meeeting Whom ?"

          options={{
         selection: true,
        showSelectAllCheckbox: false,
        selectionProps: handleSelectionProps,

        columnsButton: true,
        exportButton: false,
        search: true,
        paging: false,
        padding: 'dense',
        selection: true,
        headerStyle:{fontWeight:'bold'},
        rowStyle: rowData => ({
            backgroundColor:
              rowData.meeting_id === key
                ? "#F7CD7C"
                : "#fff",
              fontSize: 10,
             height:'10px'
          }),

    maxBodyHeight: "650px"
      }}

      actions={[
        {
          tooltip: 'Copy Selected Row',
          icon: () => <ContentCopyIcon />,

          onClick: (evt, data) => handleCopy(data)
        }
      ]}



        />

 </>
}

<div>

      <Dialog sx={{ "& .MuiDialog-container": {
      justifyContent: "flex-start",
      alignItems: "flex-start"
    }}} open={open} onClose={handleClose} scroll='paper'>
        <DialogTitle>{comp}</DialogTitle>
        <DialogContent>
          <DialogContentText>

          </DialogContentText>

           <div style={{'display':'flex'}} >

           <h4> Mark this as complete </h4>

           <Checkbox

  onChange={handleChange7}
  inputProps={{ 'aria-label': 'controlled' }}
/>


           </div>

           <span style={{'color':'red','fontSize':'11px'}}> (Please click Save to finalise the changes) </span>



          <div style={{'display':'flex', 'marginTop':'7px'}} >

          <p style={{'marginRight':'10px'}}> Meeting Type </p>



            <Autocomplete
            disableClearable
      disablePortal
      id="combo-box-demo"
      options={type_list}
      value={link}
      sx={{ width: 200 }}
      renderInput={(params) => <TextField {...params} label="Type" />}
      onChange= {(event,value)=> handleChange(value) }
    />



          </div>

       <div style={{'display':'flex', 'marginTop':'15px'}} >

          <p style={{'marginRight':'10px', 'width':'100px'}}> Event Type </p>



            <Autocomplete
            disableClearable
      disablePortal
      id="combo-box-demo"
      options={type_list99}
      value={event}
      sx={{ width: 200 }}
      renderInput={(params) => <TextField {...params} label="Event Type" />}
      onChange= {(event,value)=> handleChange99(value) }
    />



          </div>


    <div style={{'display':'flex', 'marginTop':'15px'}} >

          <p style={{'marginRight':'10px'}}> Organised By </p>



            <Autocomplete
            disableClearable
      disablePortal
      id="combo-box-demo"
      options={type_list9}
      value={organiser}
      sx={{ width: 200 }}
      renderInput={(params) => <TextField {...params} label="Organiser" />}
      onChange= {(event,value)=> handleChange9(value) }
    />



          </div>

<p> </p>


        <div style={{'display':'flex', 'marginTop':'25px'}} >

          <p style={{'marginRight':'10px'}}> Meeting Date </p>


     <TextField
        id="date"
        label=""
        type="date"

        sx={{'width':'200px'}}
        defaultValue={link1 == null ? null : link1 }
        onChange= {handleChange1}
        InputLabelProps={{
          shrink: true,
        }}
      />

          </div>

          <h4> Add Meeting Participant </h4>

          <div style={{'display':'flex', 'marginTop':'20px'}} >

          <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={top100Films}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Company Name" />}
      onChange= {(event,value)=> fetch_data(value) }
    />

    <Button  onClick ={()=> add_member(dropdown,key)} > <span style={{'color':'green','fontWeight':'bold'}}> Add </span> </Button >




          </div>

          <h4>  Meeting Participant List </h4>

          { meeting_load != null ?

          <CircularProgress />

          :

          <>
          {meeting_child == null ? null :

          <>

           {
     meeting_child[key].map(

           (content, i) =>{

           return(

           <div style={{'display':'flex'}}>

        <p style={{'width':'150px'}}>
        {content['firm_name']}
        </p>

       <IconButton onClick ={()=> delete_member(content['firm_id'],key)}  aria-label="delete">
        <DeleteIcon />
       </IconButton>


        </div>


           )

           }

)

}


</>

}

          </>
          }






        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={()=>update_close(link,link1,key, checked, organiser, event)}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>

  <Snackbar
        open={open1}
        autoHideDuration={6000}
        onClose={handleClose1}
        message="Note archived"
        action={action}
      >
 <Alert onClose={handleClose1} severity={type} sx={{ width: '100%' }}>
         {msg}
 </Alert>

  </ Snackbar>


</Grid>

 <Grid item xs={6}>

   <Stack xs={12} spacing={2}>



    <Button sx={{'float':'right'}} variant="outlined" onClick={()=>setOpen(true)}> <span style={{'fontWeight':'bold','color':'green'}}>Enter Detail </span> </Button>


        <div style={{'height':'650px'}}>

        <iframe  src={pdf}  width='100' height='500' style={{'border':'1px solid black', 'width':'700px', 'height':'650px'}}  allowTransparency="true"> </iframe>

        </div>

    </Stack>
  </Grid>

</Grid>
   </>
  );
}


const mapStateToProps = state => ({
    current_meeting: state.meeting_utility.current_meeting,
    meeting_dropdown: state.meeting_utility.meeting_dropdown,
    meeting_load: state.meeting_utility.meeting_load,
    meeting_child: state.meeting_utility.meeting_child


});

export default connect(mapStateToProps,{update_meeting_detail, add_meeting_member, update_meeting_load, get_meeting_child,delete_meeting_member, get_meeting_master, add_meeting_copy})(CurrentMeeting);


