import React, {useEffect} from 'react';




import NewsCard1 from "../components/news/NewsCard1";

import NewsDropdown1 from "../components/news/NewsDropdown1";
import NewsDropdown9 from "../components/news/NewsDropdown9";

import { get_news_data1 } from '../actions/news';

import { set_news_data1_null } from '../actions/news';

import { connect } from 'react-redux';

import Grid from '@mui/material/Grid';

import { get_news_dropdown1_list } from '../actions/news';

import { set_upvote_null } from '../actions/news';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';



const News_GIS = ({get_news_data1,news_dropdown1,news_data1,set_news_data1_null,get_news_dropdown1_list,upvote_status,set_upvote_null}) => {

 const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };


useEffect( () => {


get_news_dropdown1_list();



},[]);

useEffect( () => {

set_news_data1_null();

get_news_data1(news_dropdown1.name,news_dropdown1.type,[]);

},[news_dropdown1]);

useEffect( () => {

if(checked == false){

console.log('first');
set_news_data1_null();

get_news_data1(news_dropdown1.name,news_dropdown1.type,[]);
}

},[checked]);


useEffect( () => {


if (upvote_status == 'success'){

if(checked == false){
get_news_data1(news_dropdown1.name,news_dropdown1.type,[]);
set_upvote_null(null);
}

}



},[upvote_status]);


  return (
  <>
  <div  style={{'backgroundColor':'#F4F5FA'}}>

<div style={{'display':'flex'}} >
{checked ?  <NewsDropdown9 /> : <NewsDropdown1 />  }




        <FormControlLabel
         sx={{'marginLeft':'40px'}}
         control={<Checkbox
      checked={checked}
      onChange={handleChange}
      inputProps={{ 'aria-label': 'controlled' }}
    />} label="Select more than one sector" />

</div>


  <Grid style={{'padding':'30px'}} container spacing={5}  >

   {
     news_data1.map(

           (content, i) =>{

           return(

           <Grid item xs={12} sm={3} >
  <NewsCard1 news_id={i} />
  </Grid>


           )

           }

)

}


  </Grid>



  </div>
    </>
  );
}



const mapStateToProps = state => ({
    news_dropdown1: state.news.news_dropdown1,
    news_data1: state.news.news_data1,
    upvote_status: state.news.upvote_status


});

export default connect(mapStateToProps,{get_news_data1,set_news_data1_null,get_news_dropdown1_list,set_upvote_null})(News_GIS);


