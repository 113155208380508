import React , {useEffect,useState} from 'react';

import Grid from '@mui/material/Grid';


import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';



import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';



import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import TextField from '@mui/material/TextField';


import axios from "axios";

import CircularProgress from '@mui/material/CircularProgress';
import Autocomplete from '@mui/material/Autocomplete';

const Feed = () => {

const top100Films = ['Anant Raj Ltd.', 'Capri Global Capital Ltd.',
                   'Data Patterns (India) Ltd.',
                   'Deep Industries Ltd.',
                   'Fino Payments Bank Ltd.',
                   'Ganesh Housing Corporation Ltd.',
                   'Godawari Power And Ispat Ltd.',
                   'Gravita India Ltd.',
                   'H.G. Infra Engineering Ltd.',
                   'Infibeam Avenues Ltd.',
                   'Laxmi Organic Industries Ltd.',
                   'Meghmani Finechem Ltd',
                   'Nath Bio-Genes (India) Ltd.',
                   'The Great Eastern Shipping Company Ltd.']


const [company, setCompany] = React.useState('');

const change_company = async (value) => {

setCompany(value);


}

const [load, setLoad] = React.useState('');

const [currentwidget, setCurrentwidget] = React.useState({});

const [heading, setHeading] = React.useState('');
const [description, setDescription] = React.useState('');
const [image, setImage] = React.useState('');
const [user, setUser] = React.useState('');

var currentTime = new Date();

const [dropdown, setDropdown] = React.useState(['l']);

const [currentdate, setCurrentdate] = React.useState('test');

  const handleChange = (event) => {
    setCurrentdate(event.target.value);
  };


const [widget, setWidget] = useState({'test':[]});




const get_feed_backend = () => {

    fetch('https://transcriptanalyser.com/flip/feed-backend')
      .then(results => results.json())
      .then(data => {
        setDropdown(data['date_dropdown']);
        setWidget(data['flip']);
        setCurrentdate(data['date_dropdown'][2]);


      });
  };

const [open, setOpen] = React.useState(false);




useEffect( () => {

 get_feed_backend();

},[]);


  const handleClickOpen = (current_widget) => {

    setCurrentwidget(current_widget);

    setHeading(current_widget['heading']);
    setDescription(current_widget['summary_main']);
    setImage(current_widget['body']);
    setUser(current_widget['user_name']);


    if(current_widget['summary_main'] == 'tweet_type'){

    setOpen4(true);

    }
    else{

    setOpen(true);


    }
  };

  const handleClose = () => {

    setOpen(false);

  };




   const handleHeadingChange = (event) => {
    setHeading(event.target.value);
  };


   const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleImageChange = (event) => {
    setImage(event.target.value);
  };

  const handleUserChange = (event) => {
    setUser(event.target.value);
  };


  const handleEdit = () => {
   setLoad('load');

    fetch('https://transcriptanalyser.com/flip/manual-edit',
    {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
       "Access-Control-Allow-Origin": "*"
    },
    method: "POST",
    body: JSON.stringify({heading: heading, description: description,
                          image :image, user:user,
                         widget_id: currentwidget['widget_id']})
    }
    )
      .then(results => results.json())
      .then(data => {
        setDropdown(data['date_dropdown']);
        setWidget(data['flip']);
        setLoad('');
        //setCurrentdate(data['date_dropdown'][2]);
      });




    if(description == 'tweet_type'){

    setOpen4(false);

    }

    else{

    setOpen(false);

    }



  };



  const handleDelete = (widget_id) => {

    setLoad('load');


    fetch('https://transcriptanalyser.com/flip/manual-delete',
    {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
       "Access-Control-Allow-Origin": "*"
    },
    method: "POST",
    body: JSON.stringify({ widget_id: widget_id})
    }
    )
      .then(results => results.json())
      .then(data => {
        setDropdown(data['date_dropdown']);
        setWidget(data['flip']);
        setLoad('');
        //setCurrentdate(data['date_dropdown'][2]);
      });



    setCurrentwidget({});

    setOpen1(false);



  };

const [open1, setOpen1] = React.useState(false);

 const handleClickOpen1 = (current_widget) => {

    setCurrentwidget(current_widget);

    setOpen1(true);
  };


  const handleClose1 = () => {

    setOpen1(false);

  };


  const [open2, setOpen2] = React.useState(false);




  const handleClickOpen2 = (current_widget) => {

    setCompany('');

    setLink('');

    setCurrentwidget(current_widget);

    setHeading(current_widget['heading']);
    setDescription(current_widget['summary_main']);
    setImage(current_widget['body']);
    setUser(current_widget['user_name']);

    if(current_widget['summary_main'] == 'tweet_type'){

    setOpen3(true);

    }
    else{

    setOpen2(true);


    }


  };

  const handleClose2 = () => {

    setOpen2(false);

  };


  const handleAdd = () => {
    setLoad('load');



    fetch('https://transcriptanalyser.com/flip/manual-add',
    {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
       "Access-Control-Allow-Origin": "*"
    },
    method: "POST",
    body: JSON.stringify({heading: heading, description: description,
                          image :image, user:user,currentdate:currentdate,company:company})
    }
    )
      .then(results => results.json())
      .then(data => {
        setDropdown(data['date_dropdown']);
        setWidget(data['flip']);
        setLoad('');
        //setCurrentdate(data['date_dropdown'][2]);
      });

    if(description == 'tweet_type'){

    setOpen3(false);

    }

    else{

    setOpen2(false);

    }




  };


  const [link, setLink] = useState('');



    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileInput = (e) => {
        setSelectedFile(e.target.files[0]);

        handleUpload(e.target.files[0]);



    }

    const handleUpload = async (file) => {

    setImage('load');



    var form_data = new FormData();

    form_data.append('file', file, file.name);

    const headers={'Content-Type': file.type}







   await axios.post("https://transcriptanalyser.com/flip/uploadfile",form_data,headers)
      .then(data => {

        setImage(data['data']['link']);

      });





    }

    const [open3, setOpen3] = React.useState(false);


    const handleClose3 = () => {

    setOpen3(false);

  };


  const [open4, setOpen4] = React.useState(false);


    const handleClose4 = () => {

    setOpen4(false);

  };








    return (
    <>

    {load == 'load'  ?

    <div style={{
        'margin': '50px 0',
       'margin-bottom': '20px',
      'padding': '30px 50px',
      'text-align': 'center',
    'border-radius': '4px',}}><CircularProgress /> </div>

    :




    <div style= {{ 'display': 'flex', 'justifyContent': 'center'}} >



   <div>

   <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Date</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentdate}
          label="Date"
          onChange={handleChange}
        >


         {
     dropdown.map(

           (content, i) =>{

           return(
           <MenuItem value={content}>{content}</MenuItem>
           )
           }
           )

           }

        </Select>
      </FormControl>
    </Box>





   <Box sx={{ minWidth: 120, paddingTop: '5px' }}>
   <FormControl fullWidth>
   <Button variant= "outlined" color="secondary" size="small" onClick={()=>handleClickOpen2({'heading':'','summary_main':'','body':'','user_name':'All'})}>Add New Widget</Button>
   </FormControl>
   </Box>

   <Box sx={{ minWidth: 120, paddingTop: '5px' }}>
   <FormControl fullWidth>
   <Button variant= "outlined" color="primary" size="small" onClick={()=>handleClickOpen2({'heading':'','summary_main':'tweet_type','body':'','user_name':'All'})}>Add New Tweet</Button>
   </FormControl>
   </Box>

   {widget[currentdate].length == 0 ? <p style={{'color':'grey'}}> No Widget to show ! </p> : <> </>}

     {
     widget[currentdate].map(

           (content, i) =>{

           return(
           <>
           <p> </p>
           <Card sx={{ minWidth: 275 }}>
      <CardContent>
      {/*
      <Typography sx={{ fontSize: 11 }} color="text.secondary" gutterBottom>
        User - {content['user_name']}
        </Typography>
     */
     }
     <Typography sx={{ fontSize: 11,color: "green",'padding':'5px' }} color="text.secondary" gutterBottom>
       {content['user_name']}

      </Typography>

      <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
          {content['heading']}
      </Typography>


        <Typography sx={{ fontSize: 12 }} color="text.secondary" gutterBottom>
           {content['summary_main']}
        </Typography>
       <Typography sx={{ fontSize: 11 }} color="text.secondary" gutterBottom>

        {content['summary_main'] != 'tweet_type' ? <a href= {content['body']} target="_blank"> image </a> : <></>}

        </Typography>







      </CardContent>
      <CardActions>
        <Button variant= "outlined" size="small" onClick={()=>handleClickOpen(content)}>Edit</Button>

        <Button variant= "outlined" size="small" color="error" onClick={()=>handleClickOpen1(content)}>Delete</Button>
      </CardActions>
    </Card>
    </>


           )

           }
     )

     }

     </div>
     </div>

     }

     <Dialog open={open} onClose={handleClose}>
      {
      /*
        <DialogTitle> Widget Date: {currentwidget['widget_date']} </DialogTitle>
        */
          }
        <DialogContent>


          <DialogContentText>
     Modify the Widget Details
          </DialogContentText>




    <TextField
            autoFocus
            margin="dense"
            id="heading"
            label="Heading"
            type="heading"
            fullWidth
            onChange={handleHeadingChange}
            variant="standard"
            multiline
            defaultValue= {heading}

          />

      <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Description"
            type="description"
            fullWidth
            onChange={handleDescriptionChange}
            variant="standard"
            multiline
            defaultValue= {description}
          />


       <TextField
            autoFocus
            margin="dense"
            id="img"
            label="Image"
            type="img"
            fullWidth
            onChange={handleImageChange}
            variant="standard"
            multiline
            defaultValue= {image}
          />


         {
         /*

          <TextField
            autoFocus
            margin="dense"
            id="user"
            label="User"
            type="user"
            fullWidth
            onChange={handleUserChange}
            variant="standard"
            multiline
            defaultValue= {user}
          />

          */
          }


        </DialogContent>
        <DialogActions>
          <Button onClick={handleEdit}>Save</Button>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>




      <Dialog open={open1} onClose={handleClose1}>

        <DialogContent>


          <DialogContentText>
     Are you sure you want to delete this widget
          </DialogContentText>




        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose1}>Close</Button>
          <Button variant="outlined" color="error" onClick={()=>handleDelete(currentwidget['widget_id'])}>Delete</Button>

        </DialogActions>
      </Dialog>



      <Dialog open={open} onClose={handleClose}>
      {
      /*
        <DialogTitle> Widget Date: {currentwidget['widget_date']} </DialogTitle>
        */
          }
        <DialogContent>


          <DialogContentText>
          <p style= {{'color':'black'}} >
     Modify the Widget Details
        </p>
          </DialogContentText>





          {/*
          <div  style={{  'float':'right','marginRight':'15px','marginLeft':'auto','marginTop':'-10px'}} >
    <ToggleButtonGroup
      value={toggle}
      onChange={handleAlignment}
      color="secondary"

      exclusive


    >
      <ToggleButton  value="sector">Peer :</ToggleButton>

      <ToggleButton  value="company">Company</ToggleButton>



    </ToggleButtonGroup>
    </div>
    */
    }

    <TextField
            autoFocus
            margin="dense"
            id="heading"
            label="Heading"
            type="heading"
            fullWidth
            onChange={handleHeadingChange}
            variant="standard"
            multiline
            defaultValue= {heading}

          />

      <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Description"
            type="description"
            fullWidth
            onChange={handleDescriptionChange}
            variant="standard"
            multiline
            defaultValue= {description}
          />

          <p> </p>
          <a href = {image} target="_blank"> Image Link  </a>


          { image == 'load' ? <CircularProgress /> : <p style={{'color':'grey'}}> Re upload Image </p>  }

          <input type="file" onChange={handleFileInput}/>

    {
    /*

       <TextField
            autoFocus
            margin="dense"
            id="img"
            label="Image"
            type="img"
            fullWidth
            onChange={handleImageChange}
            variant="standard"
            multiline
            defaultValue= {image}
          />
      */
      }


        {/*
          <TextField
            autoFocus
            margin="dense"
            id="user"
            label="User"
            type="user"
            fullWidth
            onChange={handleUserChange}
            variant="standard"
            multiline
            defaultValue= {user}
          />
          */
          }




        </DialogContent>
        <DialogActions>

          <Button onClick={handleClose}>Close</Button>
          <Button variant = "outlined" color= "success" onClick={handleEdit}>Save</Button>
        </DialogActions>
      </Dialog>



     <Dialog open={open2} onClose={handleClose2}>
      {
      /*
        <DialogTitle> Widget Date: {currentwidget['widget_date']} </DialogTitle>
        */
          }
        <DialogContent>


          <DialogContentText>
     <p style={{'color':'black'}}> Add Widget </p>
          </DialogContentText>




    <TextField
            autoFocus
            margin="dense"
            id="heading"
            label="Heading"
            type="heading"
            fullWidth
            onChange={handleHeadingChange}
            variant="standard"
            multiline
            defaultValue= {heading}

          />

      <TextField
            autoFocus
            margin="dense"
            id="description"
            label="Description"
            type="description"
            fullWidth
            onChange={handleDescriptionChange}
            variant="standard"
            multiline
            defaultValue= {description}
          />

          <Autocomplete
    getOptionLabel={option => option}
     style={{'backgroundColor':'#FFFFFF', 'marginTop':'10px'}}
     disableClearable
      disablePortal
      value={company}
      size="small"
      id="combo-box-demo"
      options={top100Films}
      sx={{ width: 240 }}
      onChange= {(event,value)=> change_company(value) }
      renderInput={(params) => <TextField {...params} label="Company" />}
    />

        {
        /*


          <TextField
            autoFocus
            margin="dense"
            id="user"
            label="User"
            type="user"
            fullWidth
            onChange={handleUserChange}
            variant="standard"
            multiline
            defaultValue= {user}
          />
        */
        }



          {image == "" ? <p style={{'fontSize':'11','color':'grey'}}> Link - Nothing Uploaded </p> :
          image == 'load' ?   <CircularProgress /> :
          <p><a href ={image} target="_blank">Image </a></p>
          }

          {/*
          <TextField
            autoFocus
            margin="dense"
            id="img"
            label="Image"
            type="img"
            fullWidth
            onChange={handleImageChange}
            variant="standard"
            multiline
            defaultValue= {image}
          />
          */}



          <p> </p>

         <input type="file" onChange={handleFileInput}/>





        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2}>Close</Button>
          <Button variant= "outlined" color= "success" onClick={handleAdd}>Add</Button>

        </DialogActions>
      </Dialog>



      <Dialog open={open3} onClose={handleClose3}>
      {
      /*
        <DialogTitle> Widget Date: {currentwidget['widget_date']} </DialogTitle>
        */
          }
        <DialogContent>


          <DialogContentText>
     <p style={{'color':'black'}}> Add Tweet </p>
          </DialogContentText>




    <TextField
            autoFocus
            margin="dense"
            id="heading"
            label="Tweet Id"
            type="heading"
            fullWidth
            onChange={handleHeadingChange}
            variant="standard"
            multiline
            defaultValue= {heading}

          />


      <Autocomplete
    getOptionLabel={option => option}
     style={{'backgroundColor':'#FFFFFF', 'marginTop':'10px'}}
     disableClearable
      disablePortal
      value={company}
      size="small"
      id="combo-box-demo"
      options={top100Films}
      sx={{ width: 240 }}
      onChange= {(event,value)=> change_company(value) }
      renderInput={(params) => <TextField {...params} label="Company" />}
    />



        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose3}>Close</Button>
          <Button variant= "outlined" color= "success" onClick={handleAdd}>Add</Button>

        </DialogActions>
      </Dialog>



      <Dialog open={open4} onClose={handleClose4}>
      {
      /*
        <DialogTitle> Widget Date: {currentwidget['widget_date']} </DialogTitle>
        */
          }
        <DialogContent>


          <DialogContentText>
     Modify the Widget Details
          </DialogContentText>




    <TextField
            autoFocus
            margin="dense"
            id="heading"
            label="Tweet Id"
            type="heading"
            fullWidth
            onChange={handleHeadingChange}
            variant="standard"
            multiline
            defaultValue= {heading}

          />




        </DialogContent>
        <DialogActions>
          <Button onClick={handleEdit}>Save</Button>
          <Button onClick={handleClose4}>Close</Button>
        </DialogActions>
      </Dialog>





    </>

    )


};




export default Feed;

