import React from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { setting_toggle_value } from '../../actions/holding';

import { connect } from 'react-redux';

import { get_holding_dropdown } from '../../actions/holding';

const SettingToggle = ({setting_toggle_value,setting_toggle,get_holding_dropdown}) => {

const [alignment, setAlignment] = React.useState('one');

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);

    get_holding_dropdown();

    setting_toggle_value(newAlignment);

  };

    return (
    <>

<div  style={{  'float':'right','marginRight':'15px','marginLeft':'auto','marginTop':'-10px'}} >
    <ToggleButtonGroup
      value={setting_toggle}
      onChange={handleAlignment}
      color="secondary"

      exclusive


    >
      <ToggleButton  value="sector">Peer :</ToggleButton>

      <ToggleButton  value="company">Company</ToggleButton>



    </ToggleButtonGroup>
    </div>



    </>

    )


};


const mapStateToProps = state => ({
    setting_toggle: state.holding.setting_toggle,


});



export default connect(mapStateToProps,{setting_toggle_value,get_holding_dropdown})(SettingToggle);