import React, {useState, useEffect} from 'react';



import { get_user_setting } from '../../actions/holding';

import { update_user_setting } from '../../actions/holding';

import { create_user_setting } from '../../actions/holding';

import { delete_user_setting } from '../../actions/holding';

import { load_usersetting_null } from '../../actions/holding';

import { update_holding_load } from '../../actions/holding';



import { connect } from 'react-redux';





import Snackbar from '@mui/material/Snackbar';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import DeleteIcon from '@mui/icons-material/Delete'


const UserSetting = ({get_user_setting,user_setting, update_user_setting, load_usersetting, load_usersetting_null, create_user_setting, delete_user_setting,master_dropdown, update_holding_load,holding_load}) => {

const [key, setKey] = React.useState(0);

const [dropdown, setDropdown] = React.useState(null);

const [open, setOpen] = React.useState(false);

const [msg, setMsg] = React.useState('');
const [type, setType] = React.useState('');

  const handleClick = (message,severity) => {
    setMsg(message);
    setType(severity);


    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

 const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


useEffect( () => {

get_user_setting();

},[]);

useEffect( () => {


if(load_usersetting=='updated'){

get_user_setting();

handleClick('Succesfully Updated the row','success') ;

}

if(load_usersetting=='uploaded') {

get_user_setting();

handleClick('Succesfully Uploaded the file','success') ;

}


if(load_usersetting=='added') {

get_user_setting();

handleClick('Succesfully Added the row','success') ;

}

if(load_usersetting=='deleted') {

get_user_setting();

handleClick('Succesfully Deleted the row','success') ;

}

if(load_usersetting !='load') {

load_usersetting_null();

}


if(load_usersetting=='already_exist') {

get_user_setting();

handleClick('Company that you are trying to add already exist','error') ;

}





},[load_usersetting]);



const fetch_data = async (filter_value) => {

 setDropdown(filter_value)

    };



const add_company = (dropdown) => {





    if(dropdown != null){

    update_holding_load('load');
    create_user_setting(dropdown);

    }

    setDropdown(null);

  };



const delete_company = (dropdown) => {
    update_holding_load('load');
    delete_user_setting(dropdown);
  };




const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 500,
  });




    return (
    <>
<p style={{'fontSize':'11px', 'color':'blue'}}> Company for which report will be generated </p>

  <h4> Add Company </h4>

          <div style={{'display':'flex', 'marginTop':'20px'}} >

          <Autocomplete
      disablePortal
      filterOptions={filterOptions}
      getOptionLabel={option => option.company_name}



      id="combo-box-demo"
      options={master_dropdown}
      value = {dropdown}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Company Name" />}
      onChange= {(event,value)=> fetch_data(value) }
    />

    <Button  onClick ={()=> add_company(dropdown)} > <span style={{'color':'green','fontWeight':'bold'}}> Add </span> </Button >




          </div>

          <h4>  Company List </h4>

          { load_usersetting != null ?

          <CircularProgress />

          :

          <>
          {user_setting == null ? null :

          <>

           {
     user_setting.map(

           (content, i) =>{

           return(

           <div style={{'display':'flex'}}>

        <p style={{'width':'150px'}}>
        {content['company_name']}
        </p>

       <IconButton onClick ={()=> delete_company(content['company_name'])}  aria-label="delete">
        <DeleteIcon />
       </IconButton>


        </div>


           )

           }

)

}


</>

}

          </>
          }



<p style={{'fontSize':'11px', 'color':'grey'}}> <InfoOutlinedIcon sx={{'paddingTop':'10px'}}/> To add peers corresponding to these companies, toggle to PEER button on top right part   </p>
     <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Note archived"
        action={action}
      >
 <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
         {msg}
 </Alert>

  </ Snackbar>

    </>
    )


};






const mapStateToProps = state => ({
    user_setting: state.holding.user_setting,
    load_usersetting: state.holding.load_usersetting,
    master_dropdown: state.holding.master_dropdown,
    holding_load: state.holding.holding_load


});

export default connect(mapStateToProps,{get_user_setting,update_user_setting, load_usersetting_null, create_user_setting, delete_user_setting, update_holding_load})(UserSetting);

