import { combineReducers } from 'redux';
import meeting_utility from './meeting_utility';
import upload from './upload';
import news from './news';
import zoom from './zoom';
import holding from './holding';
import twitter from './twitter';
import admin from './admin';



export default combineReducers({
    meeting_utility,
    upload,
    news,
    zoom,
    holding,
    twitter,
    admin
});