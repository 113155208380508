import React from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { source_toggle_value } from '../../actions/news';

import { connect } from 'react-redux';

const SourceToggle = ({source_toggle_value,source_toggle}) => {

const [alignment, setAlignment] = React.useState('one');

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);

    source_toggle_value(newAlignment);

  };

    return (
    <>

<div  style={{  'float':'right','marginRight':'15px','marginLeft':'auto','marginTop':'10px'}} >
    <ToggleButtonGroup
      value={source_toggle}
      onChange={handleAlignment}
      color="secondary"

      exclusive


    >
      <ToggleButton  value="sector">Sector :</ToggleButton>

      <ToggleButton  value="company">Company</ToggleButton>



    </ToggleButtonGroup>
    </div>



    </>

    )


};


const mapStateToProps = state => ({
    source_toggle: state.news.source_toggle,


});



export default connect(mapStateToProps,{source_toggle_value})(SourceToggle);