import React, {useState, useEffect} from 'react';

import MaterialTable from "material-table";

//import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';


import { get_sector_source } from '../../actions/news';

import { update_sector_source } from '../../actions/news';

import { create_sector_source } from '../../actions/news';

import { delete_sector_source } from '../../actions/news';

import { load_sectorsource_null } from '../../actions/news';



import { connect } from 'react-redux';





import Snackbar from '@mui/material/Snackbar';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';


import SectorDropdown from "./SectorDropdown";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const SectorSource = ({get_sector_source,sector_source, update_sector_source, load_sectorsource, load_sectorsource_null, create_sector_source, delete_sector_source, sector_dropdown}) => {

const [open, setOpen] = React.useState(false);

const [msg, setMsg] = React.useState('');
const [type, setType] = React.useState('');

  const handleClick = (message,severity) => {
    setMsg(message);
    setType(severity);


    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

 const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


useEffect( () => {

get_sector_source(sector_dropdown);



},[]);

useEffect( () => {

get_sector_source(sector_dropdown);



},[sector_dropdown]);

useEffect( () => {


if(load_sectorsource=='updated'){

get_sector_source(sector_dropdown);

handleClick('Succesfully Updated the row','success') ;

}

if(load_sectorsource=='uploaded') {

get_sector_source(sector_dropdown);

handleClick('Succesfully Uploaded the file','success') ;

}


if(load_sectorsource=='added') {

get_sector_source(sector_dropdown);

handleClick('Succesfully Added the row','success') ;

}

if(load_sectorsource=='deleted') {

get_sector_source(sector_dropdown);

handleClick('Succesfully Deleted the row','success') ;

}

load_sectorsource_null();



},[load_sectorsource]);











    return (
    <>
    < SectorDropdown />
<p style={{'fontSize':'11px', 'color':'blue'}}> Sources from where Sector Specific News is fetched  </p>

 <MaterialTable
      title={sector_dropdown}
      columns={[

      { title: 'Source Name', field: 'source_name' },


      ]}

      data={sector_source}

      options={{
        paging:true
        }}

      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {


              if( ("source_name" in newData)    ) {

              create_sector_source(newData, sector_dropdown);

              }

              else {

              handleClick('At least one value was blank. Fill all values to succesfully create a row','error') ;

              }


              resolve();
            }, 1000)
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {

              update_sector_source(newData);

              resolve();
            }, 1500)
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              delete_sector_source(oldData);

              resolve()
            }, 1000)
          }),
      }}
    />


<p style={{'fontSize':'11px', 'color':'grey'}}> <InfoOutlinedIcon sx={{'paddingTop':'10px'}}/>  To get the source name to be entered above - Open any typical news link of that source  & enter the parent url which is common in all, as the source name.

     </p>


     <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Note archived"
        action={action}
      >
 <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
         {msg}
 </Alert>

  </ Snackbar>

    </>
    )


};






const mapStateToProps = state => ({
    sector_source: state.news.sector_source,
    load_sectorsource: state.news.load_sectorsource,
    sector_dropdown: state.news.sector_dropdown


});

export default connect(mapStateToProps,{get_sector_source,update_sector_source, load_sectorsource_null, create_sector_source, delete_sector_source})(SectorSource);

