import {
    ACTIVE_DASHBOARD_SUCCESS,
    ACTIVE_DASHBOARD_FAIL,

    CURRENT_MEETING_SUCCESS,
    CURRENT_MEETING_FAIL,
    HISTORICAL_MEETING_SUCCESS,
    HISTORICAL_MEETING_FAIL,
    MEETING_DROPDOWN_SUCCESS,
    MEETING_DROPDOWN_FAIL,
    MEETING_LOAD_SUCCESS,
    MEETING_LOAD_FAIL,

    MEETING_CHILD_SUCCESS,
    MEETING_CHILD_FAIL,

    MEETING_TOGGLE_SUCCESS,
    MEETING_TOGGLE_FAIL,

    MEETING_PPT_SUCCESS,
    MEETING_PPT_FAIL



} from '../actions/types';



const initialState = {
    active_dashboard: 'holding',
    current_meeting: [{'company_name':'Please Wait....','entity_name':''}],
    historical_meeting: null,
    meeting_dropdown: [
  { label: 'The Shawshank Redemption', year: 1994 }],

  meeting_load: null,
  meeting_child: null,
  meeeting_toggle: 'current',

  meeting_ppt: null


};


export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {

        case ACTIVE_DASHBOARD_SUCCESS:
            return {
                ...state,
                active_dashboard: payload

            }

        case ACTIVE_DASHBOARD_FAIL:
            return {
                ...state

            }



        case CURRENT_MEETING_SUCCESS:
            return {
                ...state,
                current_meeting: payload

            }

        case CURRENT_MEETING_FAIL:
            return {
                ...state

            }


        case HISTORICAL_MEETING_SUCCESS:
            return {
                ...state,
                historical_meeting: payload

            }

        case HISTORICAL_MEETING_FAIL:
            return {
                ...state

            }


        case MEETING_DROPDOWN_SUCCESS:
            return {
                ...state,
                meeting_dropdown: payload

            }

        case MEETING_DROPDOWN_FAIL:
            return {
                ...state

            }


        case MEETING_LOAD_SUCCESS:
            return {
                ...state,
                meeting_load: payload

            }

        case MEETING_LOAD_FAIL:
            return {
                ...state

            }


        case MEETING_CHILD_SUCCESS:
            return {
                ...state,
                meeting_child: payload

            }

        case MEETING_CHILD_FAIL:
            return {
                ...state

            }


        case MEETING_TOGGLE_SUCCESS:
            return {
                ...state,
                meeting_toggle: payload

            }

        case MEETING_TOGGLE_FAIL:
            return {
                ...state

            }

        case MEETING_PPT_SUCCESS:
            return {
                ...state,
                meeting_ppt: payload

            }

        case MEETING_PPT_FAIL:
            return {
                ...state,
                meeting_ppt: 'error'

            }




        default:
            return state


        }


    };

