import React , {useEffect,useState} from 'react';



import { get_meeting_master } from '../actions/meeting_utility';
import { get_meeting_dropdown } from '../actions/meeting_utility';

import { connect } from 'react-redux';

import CurrentMeeting from "../components/meeting_utility/CurrentMeeting";

import HistoricalMeeting from "../components/meeting_utility/HistoricalMeeting";

import MeetingToggle from "../components/meeting_utility/MeetingToggle";

import CreatePpt from "../components/meeting_utility/CreatePpt";


const MeetingUtility = ({get_meeting_master, get_meeting_dropdown,meeting_toggle}) => {




useEffect( () => {

get_meeting_master();

get_meeting_dropdown();

},[]);

    return (
    <>

   <div style={{'display':'flex','alignItems': 'center', 'justifyContent': 'center'}}>
   < CreatePpt />
   <MeetingToggle />


   </div>




<p style={{'height':'10px'}}>  </p>

{ meeting_toggle != 'history' ?
< CurrentMeeting />
:
< HistoricalMeeting />
}





    </>

    )


};


const mapStateToProps = state => ({
    meeting_toggle: state.meeting_utility.meeting_toggle



});





export default connect(mapStateToProps,{get_meeting_master,get_meeting_dropdown})(MeetingUtility);