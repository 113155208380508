import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { user_dropdown_value } from '../../actions/holding';

import { connect } from 'react-redux';



const UserDropdown = ({user_dropdown_value,user_dropdown,holding_dropdown}) => {

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = holding_dropdown;

const fetch_data = async (value) => {

user_dropdown_value(value);

}








  return (
  <div style={{'marginLeft':'30px','marginTop':'20px'}}>
    <Autocomplete
    getOptionLabel={option => option.company_name}
     style={{'backgroundColor':'#FFFFFF'}}
     disableClearable
      disablePortal
      value={user_dropdown}
      size="small"
      id="combo-box-demo"
      options={top100Films}
      sx={{ width: 240 }}
      onChange= {(event,value)=> fetch_data(value) }
      renderInput={(params) => <TextField {...params} label="Company" />}
    />
    </div>
  );
}


const mapStateToProps = state => ({
    user_dropdown: state.holding.user_dropdown,
    holding_dropdown: state.holding.holding_dropdown


});


export default connect(mapStateToProps,{user_dropdown_value})(UserDropdown);