import axios from 'axios';

import {
    TWITTER_DATA_SUCCESS,
    TWITTER_DATA_FAIL
    } from './types';


const getCookies = () =>
  document.cookie.split(';').reduce((cookies, item) => {
    const [name, value] = item.split('=');
    cookies[name] = value;
    return cookies;
  }, {});




export const get_twitter_data= (company,start) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        //const body = JSON.stringify({ company:company,start:start});

        try {
            //const res = await axios.post(`${process.env.REACT_APP_API_URL}/twitter-data`,body,config );
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/home/twitter-earning`,config );



            dispatch({
                type: TWITTER_DATA_SUCCESS,
                payload: res.data['key']
            });






        } catch (err) {
            dispatch({
                type: TWITTER_DATA_FAIL
            });


        }






};