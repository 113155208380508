import React, { useState,useEffect } from 'react';

import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Input from '@mui/material/Input';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { create_zoom_meeting } from '../actions/zoom';

import { set_zoom_null } from '../actions/zoom';

import { connect } from 'react-redux';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import LinearProgress from '@mui/material/LinearProgress';


import Box from '@mui/material/Box';
import {  useParams } from 'react-router-dom';


const Zoom = ({create_zoom_meeting,create_zoom, set_zoom_null}) => {

const { id } = useParams();

useEffect( () => {


if(create_zoom.length > 0){
setLoad(false);
handleClickOpen()

}



},[create_zoom]);

const [load, setLoad] = React.useState(false);

const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    set_zoom_null();
    setOpen(false);

  };

const [duration, setDuration] = React.useState(30);

const onSubmit = e => {
        e.preventDefault();

        console.log(topic, start_time, meeting_duration, duration);

        setLoad(true);

        create_zoom_meeting(topic, start_time,duration,id);



    };

const [formData, setFormData] = useState({
        topic: '',
        start_time: Date().toLocaleString(),
        meeting_duration: 30
    });


const { topic, start_time, meeting_duration } = formData;


const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

const handleChange9 = (event) => {
    setDuration(event.target.value);
  };



//'align-items':'center','justify-content':'center',

    return (
    <>


  <div style={{
        'margin': '50px 0',
       'margin-bottom': '20px',
      'padding': '30px 50px',
      'text-align': 'center',
    'border-radius': '4px',}}>

 <h1 style={{'color':'blue'}}>Create Zoom Meeting</h1>
<form onSubmit={e => onSubmit(e)}>
                <div className='form-group' >
                 <label style={{'width':'200px','display': 'inline-block', 'fontWeight':'bold'}} for="topic">Meeting Topic</label>
                    <Input
                        className='form-control'
                        sx={{minWidth: 200}}

                        placeholder='Enter Meeting Topic'
                        name='topic'
                        value={topic}
                        onChange={e => onChange(e)}

                        required
                    />
                </div>
                 <p> </p>



                     <div className='form-group' style={{'margin-top':'25px','margin-left':'-30px'}}>

            <label style={{'width':'200px','display': 'inline-block', 'fontWeight':'bold'}} for="meeting_duration">Meeting Duration</label>
            <Select sx={{ minWidth: 130, height:40 }} id="meeting_duration" name="meeting_duration" value={duration} onChange={handleChange9}>
              <MenuItem value={30}>30 min</MenuItem>
              <MenuItem value={45}>45 min</MenuItem>
              <MenuItem value={60}>1 hr</MenuItem>
              <MenuItem value={90}>1 hr 30 min</MenuItem>
              <MenuItem value={120}>2 hr</MenuItem>
              <MenuItem value={150}>2 hr 30 min</MenuItem>
              <MenuItem value={180}>3 hr</MenuItem>
            </Select>

            </div>

            <div className='form-group' style={{'margin-top':'25px'}}>
                <label style={{'width':'200px','display': 'inline-block','fontWeight':'bold'}} for="start_time">Meeting Time</label>



                  <Input
            className='form-control'
            type='datetime-local'
            placeholder='Enter Start time'
            name='start_time'
            value={start_time}
            onChange={e => onChange(e)}
            minLength='6'
            required
        />

                </div>


                <p> </p>
                <Button style={{'margin-top':'25px'}} variant="contained" className='btn btn-primary' type='submit'>Create Meeting</Button>
            </form>


</div>


{ load ==true ?
<div style={{'width':'40%','margin':'auto'}}>
      <LinearProgress />
  </div>
  : null }





 <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Creating Meeting Status</DialogTitle>
      <DialogContent>
          <DialogContentText>

{create_zoom.length ==4 ?
  <>
            <p>
             Link for the zoom meeting is   {create_zoom[0]}   <br/>
             meetingPassword- {create_zoom[1]}  <br/>
             Meeting Start time- {create_zoom[2]} <br/>
             Meeeting Duration- {create_zoom[3]} minutes <br/>
             Meeting Host key - 479971 <br/>

             </p>

             <p style={{'color':'green','fontSize':'11px','fontWeight':'bold'}}>
                           Email containing the meeting details is sent at your email which you are using to login GoIndiaConnect
                </p>

                <p style={{'color':'red','fontSize':'11px'}}>
               Meeting Details will only be displayed once.
             </p>
    </>
          :
          <p> Error in Creating Meeting </p>

     }
          </DialogContentText>
      </DialogContent>

      <DialogActions>

          <Button onClick={handleClose}>Close</Button>
      </DialogActions>

 </Dialog>

    </>

    )


};



const mapStateToProps = state => ({
    create_zoom: state.zoom.create_zoom


});


export default connect(mapStateToProps,{create_zoom_meeting,set_zoom_null})(Zoom);



