
export const ACTIVE_DASHBOARD_SUCCESS = 'ACTIVE_DASHBOARD_SUCCESS';
export const ACTIVE_DASHBOARD_FAIL = 'ACTIVE_DASHBOARD_FAIL';


export const CURRENT_MEETING_SUCCESS = 'CURRENT_MEETING_SUCCESS';
export const CURRENT_MEETING_FAIL = 'CURRENT_MEETING_FAIL';

export const HISTORICAL_MEETING_SUCCESS = 'HISTORICAL_MEETING_SUCCESS';
export const HISTORICAL_MEETING_FAIL = 'HISTORICAL_MEETING_FAIL';


export const MEETING_DROPDOWN_SUCCESS = 'MEETING_DROPDOWN_SUCCESS';
export const MEETING_DROPDOWN_FAIL = 'MEETING_DROPDOWN_FAIL';


export const MEETING_LOAD_SUCCESS = 'MEETING_LOAD_SUCCESS';
export const MEETING_LOAD_FAIL = 'MEETING_LOAD_FAIL';

export const MEETING_CHILD_SUCCESS = 'MEETING_CHILD_SUCCESS';
export const MEETING_CHILD_FAIL = 'MEETING_CHILD_FAIL';


export const MEETING_TOGGLE_SUCCESS = 'MEETING_TOGGLE_SUCCESS';
export const MEETING_TOGGLE_FAIL = 'MEETING_TOGGLE_FAIL';






export const UPLOAD_TABLE_SUCCESS = 'UPLOAD_TABLE_SUCCESS';
export const UPLOAD_TABLE_FAIL = 'UPLOAD_TABLE_FAIL';

export const SYNC_UPLOAD_SUCCESS = 'SYNC_UPLOAD_SUCCESS';
export const SYNC_UPLOAD_FAIL = 'SYNC_UPLOAD_FAIL';


export const UPLOAD_NULL_SUCCESS = 'UPLOAD_NULL_SUCCESS';
export const UPLOAD_NULL_FAIL = 'UPLOAD_NULL_FAIL';



export const NEWS_DATA1_SUCCESS = 'NEWS_DATA1_SUCCESS';
export const NEWS_DATA1_FAIL = 'NEWS_DATA1_FAIL';

export const NEWS_NULL_SUCCESS = 'NEWS_NULL_SUCCESS';
export const NEWS_NULL_FAIL = 'NEWS_NULL_FAIL';

export const DROPDOWN_LIST_NEWS_SUCCESS = 'DROPDOWN_LIST_NEWS_SUCCESS';
export const DROPDOWN_LIST_NEWS_FAIL = 'DROPDOWN_LIST_NEWS_FAIL';

export const NEWS_DROPDOWN1_SUCCESS = 'NEWS_DROPDOWN1_SUCCESS';
export const NEWS_DROPDOWN1_FAIL = 'NEWS_DROPDOWN1_FAIL';

export const UPVOTE_STATUS_SUCCESS = 'UPVOTE_STATUS_SUCCESS';
export const UPVOTE_STATUS_FAIL = 'UPVOTE_STATUS_FAIL';

export const UPVOTE_NULL_SUCCESS = 'UPVOTE_NULL_SUCCESS';
export const UPVOTE_NULL_FAIL = 'UPVOTE_NULL_FAIL';

export const NEWS_TOGGLE_SUCCESS = 'NEWS_TOGGLE_SUCCESS';
export const NEWS_TOGGLE_FAIL = 'NEWS_TOGGLE_FAIL';

export const NEWS_DATA2_SUCCESS = 'NEWS_DATA2_SUCCESS';
export const NEWS_DATA2_FAIL = 'NEWS_DATA2_FAIL';

export const EMAIL_SECTOR_SUCCESS = 'EMAIL_SECTOR_SUCCESS';
export const EMAIL_SECTOR_FAIL = 'EMAIL_SECTOR_FAIL';


export const NEWS_EMAIL_SUCCESS = 'NEWS_EMAIL_SUCCESS';
export const NEWS_EMAIL_FAIL = 'NEWS_EMAIL_FAIL';


export const SOURCE_TOGGLE_SUCCESS = 'SOURCE_TOGGLE_SUCCESS';
export const SOURCE_TOGGLE_FAIL = 'SOURCE_TOGGLE_FAIL';

export const COMPANY_SOURCE_SUCCESS = 'COMPANY_SOURCE_SUCCESS';
export const COMPANY_SOURCE_FAIL = 'COMPANY_SOURCE_FAIL';

export const LOAD_COMPANYSOURCE_SUCCESS = 'LOAD_COMPANYSOURCE_SUCCESS';
export const LOAD_COMPANYSOURCE_FAIL = 'LOAD_COMPANYSOURCE_FAIL';

export const NULL_COMPANYSOURCE_SUCCESS = 'NULL_COMPANYSOURCE_SUCCESS';
export const NULL_COMPANYSOURCE_FAIL = 'NULL_COMPANYSOURCE_FAIL';



export const SECTOR_DROPDOWN_SUCCESS = 'SECTOR_DROPDOWN_SUCCESS';
export const SECTOR_DROPDOWN_FAIL = 'SECTOR_DROPDOWN_FAIL';


export const SECTOR_SOURCE_SUCCESS = 'SECTOR_SOURCE_SUCCESS';
export const SECTOR_SOURCE_FAIL = 'SECTOR_SOURCE_FAIL';

export const LOAD_SECTORSOURCE_SUCCESS = 'LOAD_SECTORSOURCE_SUCCESS';
export const LOAD_SECTORSOURCE_FAIL = 'LOAD_SECTORSOURCE_FAIL';

export const NULL_SECTORSOURCE_SUCCESS = 'NULL_SECTORSOURCE_SUCCESS';
export const NULL_SECTORSOURCE_FAIL = 'NULL_SECTORSOURCE_FAIL';

export const MEETING_PPT_SUCCESS = 'MEETING_PPT_SUCCESS';
export const MEETING_PPT_FAIL = 'MEETING_PPT_FAIL';


export const CREATE_ZOOM_SUCCESS = 'CREATE_ZOOM_SUCCESS';
export const CREATE_ZOOM_FAIL = 'CREATE_ZOOM_FAIL';


export const ZOOM_NULL_SUCCESS = 'ZOOM_NULL_SUCCESS';
export const ZOOM_NULL_FAIL = 'ZOOM_NULL_FAIL';


export const CREATE_HOLDING_SUCCESS = 'CREATE_HOLDING_SUCCESS';
export const CREATE_HOLDING_FAIL = 'CREATE_HOLDING_FAIL';


export const HOLDING_NULL_SUCCESS = 'HOLDING_NULL_SUCCESS';
export const HOLDING_NULL_FAIL = 'HOLDING_NULL_FAIL';

export const SETTING_TOGGLE_SUCCESS = 'SETTING_TOGGLE_SUCCESS';
export const SETTING_TOGGLE_FAIL = 'SETTING_TOGGLE_FAIL';

export const USER_SETTING_SUCCESS = 'USER_SETTING_SUCCESS';
export const USER_SETTING_FAIL = 'USER_SETTING_FAIL';

export const LOAD_USERSETTING_SUCCESS = 'LOAD_USERSETTING_SUCCESS';
export const LOAD_USERSETTING_FAIL = 'LOAD_USERSETTING_FAIL';

export const NULL_USERSETTING_SUCCESS = 'NULL_USERSETTING_SUCCESS';
export const NULL_USERSETTING_FAIL = 'NULL_USERSETTING_FAIL';



export const USER_DROPDOWN_SUCCESS = 'USER_DROPDOWN_SUCCESS';
export const USER_DROPDOWN_FAIL = 'USER_DROPDOWN_FAIL';


export const COMPANY_SETTING_SUCCESS = 'COMPANY_SETTING_SUCCESS';
export const COMPANY_SETTING_FAIL = 'COMPANY_SETTING_FAIL';

export const LOAD_COMPANYSETTING_SUCCESS = 'LOAD_COMPANYSETTING_SUCCESS';
export const LOAD_COMPANYSETTING_FAIL = 'LOAD_COMPANYSETTING_FAIL';

export const NULL_COMPANYSETTING_SUCCESS = 'NULL_COMPANYSETTING_SUCCESS';
export const NULL_COMPANYSETTING_FAIL = 'NULL_COMPANYSETTING_FAIL';


export const HOLDING_DROPDOWN_SUCCESS = 'HOLDING_DROPDOWN_SUCCESS';
export const HOLDING_DROPDOWN_FAIL = 'HOLDING_DROPDOWN_FAIL';


export const HOLDING_LOAD_SUCCESS = 'HOLDING_LOAD_SUCCESS';
export const HOLDING_LOAD_FAIL = 'HOLDING_LOAD_FAIL';


export const TWITTER_DATA_SUCCESS = 'TWITTER_DATA_SUCCESS';
export const TWITTER_DATA_FAIL = 'TWITTER_DATA_FAIL';



export const USER9_DROPDOWN_SUCCESS = 'USER9_DROPDOWN_SUCCESS';
export const USER9_DROPDOWN_FAIL = 'USER9_DROPDOWN_FAIL';

export const ADMIN_DROPDOWN_SUCCESS = 'ADMIN_DROPDOWN_SUCCESS';
export const ADMIN_DROPDOWN_FAIL = 'ADMIN_DROPDOWN_FAIL';

export const ADMIN_TOGGLE_SUCCESS = 'ADMIN_TOGGLE_SUCCESS';
export const ADMIN_TOGGLE_FAIL = 'ADMIN_TOGGLE_FAIL';



