import axios from 'axios';

import {
   NEWS_DATA1_SUCCESS,
   NEWS_DATA1_FAIL,
   NEWS_NULL_SUCCESS,
   NEWS_NULL_FAIL,
   DROPDOWN_LIST_NEWS_SUCCESS,
   DROPDOWN_LIST_NEWS_FAIL,
   NEWS_DROPDOWN1_SUCCESS,
   NEWS_DROPDOWN1_FAIL,
   UPVOTE_STATUS_SUCCESS,
   UPVOTE_STATUS_FAIL,
   UPVOTE_NULL_SUCCESS,
   UPVOTE_NULL_FAIL,
   NEWS_TOGGLE_SUCCESS,
   NEWS_TOGGLE_FAIL,
   NEWS_DATA2_SUCCESS,
   NEWS_DATA2_FAIL,
   EMAIL_SECTOR_SUCCESS,
   EMAIL_SECTOR_FAIL,
   NEWS_EMAIL_SUCCESS,
   NEWS_EMAIL_FAIL,
   SOURCE_TOGGLE_SUCCESS,
   SOURCE_TOGGLE_FAIL,

   COMPANY_SOURCE_SUCCESS,
   COMPANY_SOURCE_FAIL,
   LOAD_COMPANYSOURCE_SUCCESS,
   LOAD_COMPANYSOURCE_FAIL,
   NULL_COMPANYSOURCE_SUCCESS,
   NULL_COMPANYSOURCE_FAIL,


   SECTOR_DROPDOWN_SUCCESS,
   SECTOR_DROPDOWN_FAIL,

   SECTOR_SOURCE_SUCCESS,
   SECTOR_SOURCE_FAIL,
   LOAD_SECTORSOURCE_SUCCESS,
   LOAD_SECTORSOURCE_FAIL,
   NULL_SECTORSOURCE_SUCCESS,
   NULL_SECTORSOURCE_FAIL,




    } from './types';


const getCookies = () =>
  document.cookie.split(';').reduce((cookies, item) => {
    const [name, value] = item.split('=');
    cookies[name] = value;
    return cookies;
  }, {});




export const get_news_data1= (company,type, sector_list) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ company:company,type:type, sector_list:sector_list});

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/news-data1`,body,config );



            dispatch({
                type: NEWS_DATA1_SUCCESS,
                payload: res.data['key']
            });



        } catch (err) {
            dispatch({
                type: NEWS_DATA1_FAIL
            });


        }






};


export const set_news_data1_null = () => async dispatch => {

      try{
            dispatch({
                type: NEWS_NULL_SUCCESS
            });

           }

       catch (err) {
            dispatch({
                type: NEWS_NULL_FAIL
            });
        }



};


export const get_news_dropdown1_list= () => async dispatch => {




        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/dropdown1-list-news`);



            dispatch({
                type: DROPDOWN_LIST_NEWS_SUCCESS,
                payload: res.data['key']
            });




        } catch (err) {
            dispatch({
                type: DROPDOWN_LIST_NEWS_FAIL
            });
        }


};



export const news_dropdown1_value = (value) => async dispatch => {

      try{
            dispatch({
                type: NEWS_DROPDOWN1_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: NEWS_DROPDOWN1_FAIL
            });
        }



};



export const update_upvote_status= (news_id, upvote, type) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ news_id:news_id,upvote:upvote, type: type});

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/update-upvote-status`,body,config );



            dispatch({
                type: UPVOTE_STATUS_SUCCESS,
                payload: res.data['key']
            });



        } catch (err) {
            dispatch({
                type: UPVOTE_STATUS_FAIL
            });


        }






};




export const set_upvote_null = (value) => async dispatch => {

      try{
            dispatch({
                type: UPVOTE_NULL_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: UPVOTE_NULL_FAIL
            });
        }



};


export const news_toggle_value = (value) => async dispatch => {

      try{
            dispatch({
                type: NEWS_TOGGLE_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: NEWS_TOGGLE_FAIL
            });
        }



};



export const get_news_data2= () => async dispatch => {


        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/news-data2-utility`);



            dispatch({
                type: NEWS_DATA2_SUCCESS,
                payload: res.data['key']
            });




        } catch (err) {
            dispatch({
                type: NEWS_DATA2_FAIL
            });
        }


};




export const update_email_sector = (value) => async dispatch => {

      try{
            dispatch({
                type: EMAIL_SECTOR_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: EMAIL_SECTOR_FAIL
            });
        }



};



export const send_news_email= (time, email_sector, email,checked) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ time:time,email_sector:email_sector, email: email, checked:checked});

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/send-news-email`,body,config );



            dispatch({
                type: NEWS_EMAIL_SUCCESS,
                payload: res.data['key']
            });



        } catch (err) {
            dispatch({
                type: NEWS_EMAIL_FAIL
            });


        }






};



export const source_toggle_value = (value) => async dispatch => {

      try{
            dispatch({
                type: SOURCE_TOGGLE_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: SOURCE_TOGGLE_FAIL
            });
        }



};


export const get_company_source= () => async dispatch => {




        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/company-source`);



            dispatch({
                type: COMPANY_SOURCE_SUCCESS,
                payload: res.data['key']
            });




        } catch (err) {
            dispatch({
                type: COMPANY_SOURCE_FAIL
            });
        }


};




export const update_company_source= (data_obj ) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ data_obj:data_obj});

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/update-company-source`,body,config );



            dispatch({
                type: LOAD_COMPANYSOURCE_SUCCESS,
                payload: res.data['key']
            });



        } catch (err) {
            dispatch({
                type: LOAD_COMPANYSOURCE_FAIL
            });


        }






};



export const load_companysource_null = () => async dispatch => {

      try{
            dispatch({
                type: NULL_COMPANYSOURCE_SUCCESS
            });

           }

       catch (err) {
            dispatch({
                type: NULL_COMPANYSOURCE_FAIL
            });
        }



};




export const create_company_source = (data_obj ) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ data_obj:data_obj});

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/create-company-source`,body,config );



            dispatch({
                type: LOAD_COMPANYSOURCE_SUCCESS,
                payload: res.data['key']
            });



        } catch (err) {
            dispatch({
                type: LOAD_COMPANYSOURCE_FAIL
            });


        }






};


export const delete_company_source = (data_obj ) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ data_obj:data_obj});

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/deleted-company-source`,body,config );



            dispatch({
                type: LOAD_COMPANYSOURCE_SUCCESS,
                payload: res.data['key']
            });



        } catch (err) {
            dispatch({
                type: LOAD_COMPANYSOURCE_FAIL
            });


        }






};








export const sector_dropdown_value = (value) => async dispatch => {

      try{
            dispatch({
                type: SECTOR_DROPDOWN_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: SECTOR_DROPDOWN_FAIL
            });
        }



};





export const get_sector_source= (sector ) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ sector:sector});

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/sector-source`,body,config );



            dispatch({
                type: SECTOR_SOURCE_SUCCESS,
                payload: res.data['key']
            });



        } catch (err) {
            dispatch({
                type: SECTOR_SOURCE_FAIL
            });


        }






};



export const update_sector_source= (data_obj ) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ data_obj:data_obj});

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/update-sector-source`,body,config );



            dispatch({
                type: LOAD_SECTORSOURCE_SUCCESS,
                payload: res.data['key']
            });



        } catch (err) {
            dispatch({
                type: LOAD_SECTORSOURCE_FAIL
            });


        }






};



export const load_sectorsource_null = () => async dispatch => {

      try{
            dispatch({
                type: NULL_SECTORSOURCE_SUCCESS
            });

           }

       catch (err) {
            dispatch({
                type: NULL_SECTORSOURCE_FAIL
            });
        }



};




export const create_sector_source = (data_obj,sector_dropdown ) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ data_obj:data_obj, sector_dropdown:sector_dropdown});

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/create-sector-source`,body,config );



            dispatch({
                type: LOAD_SECTORSOURCE_SUCCESS,
                payload: res.data['key']
            });



        } catch (err) {
            dispatch({
                type: LOAD_SECTORSOURCE_FAIL
            });


        }






};


export const delete_sector_source = (data_obj ) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ data_obj:data_obj});

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/deleted-sector-source`,body,config );



            dispatch({
                type: LOAD_SECTORSOURCE_SUCCESS,
                payload: res.data['key']
            });



        } catch (err) {
            dispatch({
                type: LOAD_SECTORSOURCE_FAIL
            });


        }






};










