import React from 'react';

import { makeStyles } from '@mui/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import Avatar from '@mui/material/Avatar';
import DateRangeIcon from '@mui/icons-material/DateRange';

import IconButton from '@mui/material/IconButton';

import { connect } from 'react-redux';

import { Link as RouterLink } from 'react-router-dom'
import Link from '@mui/material/Link'

import Skeleton from '@mui/material/Skeleton';

import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import { update_upvote_status } from '../../actions/news';

import { set_upvote_null } from '../../actions/news';

import CircularProgress from '@mui/material/CircularProgress';

import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles({
  root: {
    minWidth: 255,

  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});



const NewsCard1 = ({news_data1,news_id,update_upvote_status, upvote_status,set_upvote_null}) => {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;


 const updateUpvote = (news_id,upvote, type ) => {


update_upvote_status(news_id, upvote, type);


}



  return (
  <>
  {news_data1[0] == null ?
    <>
  <Skeleton variant="text" width={1400} height={40} />
  <Skeleton variant="text" width={1400}  height={40} />
  <Skeleton variant="text" width={1400} height={40} />
  <Skeleton variant="text" width={1400} height={40} />
  </>
   :

   <div >


    <Card  style={{'borderRadius':'20px'}} className={classes.root}>


    { news_data1[news_id]['upvote']=='yes' ?
     <IconButton onClick={()=> updateUpvote(news_data1[news_id]['news_id'],'removevote','company')} style={{'float':'right'}} aria-label="delete">
  <ThumbUpIcon color="primary" />
</IconButton>
:
 <IconButton  onClick={()=> updateUpvote(news_data1[news_id]['news_id'],'upvote','company')} style={{'float':'right'}} aria-label="delete">
  <ThumbUpIcon />
</IconButton>

}



   <Link underline='none' component="a" href={news_data1[news_id]['link']} target="_blank">

      <CardContent>
        <Typography className={classes.title} color="textPrimary" gutterBottom>
          {news_data1[news_id]['description']}
          <span style={{'color':'grey', 'fontSize':'10px','marginLeft':'5px'}}>&nbsp;{news_data1[news_id]['source']} </span>
        </Typography>






      </CardContent>

      <CardActions disableSpacing>



      <IconButton   aria-label="add to favorites">

          <Avatar alt="Remy Sharp" style={{ height: '25px', width: '25px' }} src='/static/img/calender.png' />

        </IconButton>
        <p style={{'fontSize':'10px','color':'grey'}}   >
           {news_data1[news_id]['pubDate1']}
        </p>

  <Tooltip title={news_data1[news_id]['source']}>
        <IconButton style={{'marginTop':'-7px','marginLeft':'auto'}} aria-label="share">
        {news_data1[news_id]['news_logo'] == 'none' ?
        <p style={{'fontSize':'10px','color':'blue'}}> {news_data1[news_id]['source']} </p>
        :
          <Avatar alt="Remy Sharp" src= {news_data1[news_id]['news_logo']} />
          }
        </IconButton>
  </Tooltip>



      </CardActions>
 </Link>
    </Card>

    </div>

    }
    </>
  );
}



const mapStateToProps = state => ({
    news_data1: state.news.news_data1,
    upvote_status: state.news.upvote_status


});

export default connect(mapStateToProps,{update_upvote_status,set_upvote_null})(NewsCard1);

