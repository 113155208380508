import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { sector_dropdown_value } from '../../actions/news';

import { connect } from 'react-redux';

const SectorDropdown = ({sector_dropdown_value,sector_dropdown}) => {

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = ['Agri','Bank','Realty','Finance','Infrastructure','Chemicals','IT','Iron & Steel',
             'Healthcare','Automobile & Ancillaries','Aviation','Crude Oil','FMCG','Textile'];

const fetch_data = async (value) => {

sector_dropdown_value(value);

}


  return (
  <div style={{'marginLeft':'30px','marginTop':'20px'}}>
    <Autocomplete
     style={{'backgroundColor':'#FFFFFF'}}
     disableClearable
      disablePortal
      value={sector_dropdown}
      size="small"
      id="combo-box-demo"
      options={top100Films}
      sx={{ width: 240 }}
      onChange= {(event,value)=> fetch_data(value) }
      renderInput={(params) => <TextField {...params} label="Sector" />}
    />
    </div>
  );
}


const mapStateToProps = state => ({
    sector_dropdown: state.news.sector_dropdown


});


export default connect(mapStateToProps,{sector_dropdown_value})(SectorDropdown);