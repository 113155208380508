import {

    USER9_DROPDOWN_SUCCESS,
    USER9_DROPDOWN_FAIL,

    ADMIN_DROPDOWN_SUCCESS,
    ADMIN_DROPDOWN_FAIL,

    ADMIN_TOGGLE_SUCCESS,
    ADMIN_TOGGLE_FAIL


} from '../actions/types';



const initialState = {

    user_dropdown : '',
    master_dropdown: ["Please Wait"],
    admin_dropdown:{ "company_name": {
                "ashish": [
                    "Allcargo Logistics Ltd."]}
                    },

    admin_toggle : 'company'




};


export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {


        case USER9_DROPDOWN_SUCCESS:
            return {
                ...state,
                user_dropdown: payload

            }

        case USER9_DROPDOWN_FAIL:
            return {
                ...state

            }



        case ADMIN_DROPDOWN_SUCCESS:
            return {
                ...state,
                admin_dropdown: payload['company'],
                master_dropdown: payload['master']

            }

        case ADMIN_DROPDOWN_FAIL:
            return {
                ...state

            }


        case ADMIN_TOGGLE_SUCCESS:
            return {
                ...state,
                admin_toggle: payload

            }

        case ADMIN_TOGGLE_FAIL:
            return {
                ...state

            }






        default:
            return state


        }


    };

