import React from 'react';

import News_GIS from './News_GIS';

import News_General from './News_General';

import NewsToggle from "../components/news/NewsToggle";

import { connect } from 'react-redux';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import SendIcon from '@mui/icons-material/Send';

import Autocomplete from '@mui/material/Autocomplete';

import NewsDropdown99 from "../components/news/NewsDropdown99";

import { send_news_email } from '../actions/news';

import { update_email_sector } from '../actions/news';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import IconButton from '@mui/material/IconButton';
import SettingsIcon from '@mui/icons-material/Settings';

import SourceToggle from "../components/news/SourceToggle";

import SectorSource from "../components/news/SectorSource";
import CompanySource from "../components/news/CompanySource";

const News = ({news_toggle,email_sector, send_news_email, update_email_sector, source_toggle}) => {

const [open1, setOpen1] = React.useState(false);

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };



const [open, setOpen] = React.useState(false);

const [time, setTime] = React.useState('Past 24 hrs');

  const handleClickOpen = () => {
    setOpen(true);

  };

  const handleClose = () => {
    setOpen(false);
    setChecked(true);
    setTime('Past 24 hrs');
    setEmail('');
    update_email_sector([]);
  };


  const handleEmail = () => {

    send_news_email(time, email_sector, email,checked );
    setOpen(false);
    setChecked(true);
    setTime('Past 24 hrs');
    setEmail('');
    update_email_sector([]);
  };


  const handleChange7 = (value) => {

    setTime(value.label);
  };

  const type_list7 = [
  { label: 'Past Week', year: 1 },
  { label: 'Past 24 hrs', year: 2 }
  ];

const [email, setEmail] = React.useState('');

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };


 const [checked, setChecked] = React.useState(true);

  const handleChange5 = (event) => {
    setChecked(event.target.checked);
  };

    return (
    <>
<div style={{'display':'flex','justifyContent':'space-between','marginLeft':'40px'}}>



   <Button   variant="outlined" onClick={handleClickOpen1} endIcon={<SettingsIcon />}>
        Modify Source
      </Button>


      <Button   variant="contained" onClick={handleClickOpen} endIcon={<SendIcon />}>
        Send Mail
      </Button>



</div>


< NewsToggle />


{ news_toggle == 'company' ?
< News_GIS />

:

< News_General />

}


<Dialog open={open} onClose={handleClose}>
        <DialogTitle>Email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the following details to receive upvoted News via email
          </DialogContentText>
 <p> </p>
            <Autocomplete
            disableClearable
      disablePortal
      id="combo-box-demo"
      options={type_list7}
      value={time}
      sx={{ width: 200, marginLeft:'40px' }}
      renderInput={(params) => <TextField {...params} label="Time Period" />}
      onChange= {(event,value)=> handleChange7(value) }
    />
  <p> </p>

  < NewsDropdown99 />


          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            onChange={handleEmailChange}
            variant="standard"
          />
          <p> </p>



          {/*
<FormControlLabel control={<Checkbox
      checked={checked}
      onChange={handleChange5}
      inputProps={{ 'aria-label': 'controlled' }}
    />} label="Include Only Upvoted News" />
    */}

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleEmail}>Send</Button>
        </DialogActions>
      </Dialog>



    <Dialog open={open1} onClose={handleClose1}>
        <DialogTitle>  Setting</DialogTitle>
        <DialogContent>
          <DialogContentText>
     Modify the News Sources that are being used to fetch the news
          </DialogContentText>
          <SourceToggle />

          {source_toggle == 'company' ?
         < CompanySource />

          :

         < SectorSource />

          }
        </DialogContent>
        <DialogActions>

          <Button onClick={handleClose1}>Close</Button>
        </DialogActions>
      </Dialog>

    </>

    )


};

const mapStateToProps = state => ({
    news_toggle: state.news.news_toggle,
    email_sector: state.news.email_sector,
    source_toggle: state.news.source_toggle



});



export default connect(mapStateToProps,{send_news_email, update_email_sector})(News);



