import axios from 'axios';

import {


    USER9_DROPDOWN_SUCCESS,
    USER9_DROPDOWN_FAIL,


    ADMIN_DROPDOWN_SUCCESS,
    ADMIN_DROPDOWN_FAIL,

    ADMIN_TOGGLE_SUCCESS,
    ADMIN_TOGGLE_FAIL


    } from './types';


const getCookies = () =>
  document.cookie.split(';').reduce((cookies, item) => {
    const [name, value] = item.split('=');
    cookies[name] = value;
    return cookies;
  }, {});



export const get_admin_dropdown= () => async dispatch => {




        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/admin/get-holding-dropdown`);



            dispatch({
                type: ADMIN_DROPDOWN_SUCCESS,
                payload: res.data['key']
            });



           {/*

            dispatch({
                type: USER9_DROPDOWN_SUCCESS,
                payload: res.data['key']['company']['company_name']
            });
            */}





        } catch (err) {
            dispatch({
                type: ADMIN_DROPDOWN_FAIL
            });



        }


};


export const user_dropdown_value = (value) => async dispatch => {

      try{
            dispatch({
                type: USER9_DROPDOWN_SUCCESS,
                payload: value
            });






           }

       catch (err) {
            dispatch({
                type: USER9_DROPDOWN_FAIL
            });
        }



};



export const admin_toggle_value = (value) => async dispatch => {

      try{
            dispatch({
                type: ADMIN_TOGGLE_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: ADMIN_TOGGLE_FAIL
            });
        }



};


