import {
    CREATE_ZOOM_SUCCESS,
    CREATE_ZOOM_FAIL,
    ZOOM_NULL_SUCCESS,
    ZOOM_NULL_FAIL

} from '../actions/types';



const initialState = {
    create_zoom:[]

};


export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case CREATE_ZOOM_SUCCESS:
            return {
                ...state,
                create_zoom: payload

            }

        case CREATE_ZOOM_FAIL:
            return {
                ...state

            }

        case ZOOM_NULL_SUCCESS:
            return {
                ...state,
                create_zoom: []

            }

        case ZOOM_NULL_FAIL:
            return {
                ...state

            }



        default:
            return state


        }


    };

