import axios from 'axios';

import {
    CREATE_ZOOM_SUCCESS,
    CREATE_ZOOM_FAIL,
    ZOOM_NULL_SUCCESS,
    ZOOM_NULL_FAIL

    } from './types';


const getCookies = () =>
  document.cookie.split(';').reduce((cookies, item) => {
    const [name, value] = item.split('=');
    cookies[name] = value;
    return cookies;
  }, {});






export const create_zoom_meeting= (topic, start_time,duration,id) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ topic:topic, start_time:start_time,duration:duration, id:id });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/create-zoom`,body,config );



            dispatch({
                type: CREATE_ZOOM_SUCCESS,
                payload: res.data['key']
            });




        } catch (err) {
            dispatch({
                type: CREATE_ZOOM_FAIL
            });


        }






};


export const set_zoom_null = (value) => async dispatch => {

      try{
            dispatch({
                type: ZOOM_NULL_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: ZOOM_NULL_FAIL
            });
        }



};



