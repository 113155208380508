import axios from 'axios';

import {
    UPLOAD_TABLE_SUCCESS,
    UPLOAD_TABLE_FAIL,
    SYNC_UPLOAD_SUCCESS,
    SYNC_UPLOAD_FAIL,
    UPLOAD_NULL_SUCCESS,
    UPLOAD_NULL_FAIL

    } from './types';


const getCookies = () =>
  document.cookie.split(';').reduce((cookies, item) => {
    const [name, value] = item.split('=');
    cookies[name] = value;
    return cookies;
  }, {});






export const upload_table_value= () => async dispatch => {




        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/upload-data`);

            const payload = res.data['key'] ;


            dispatch({
                type: UPLOAD_TABLE_SUCCESS,
                payload: payload
            });
        } catch (err) {
            dispatch({
                type: UPLOAD_TABLE_FAIL
            });
        }


};


export const sync_upload_value= (sheet_name,fincode) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ sheet_name:sheet_name, fincode:fincode });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/sheet-db`,body,config );



            dispatch({
                type: SYNC_UPLOAD_SUCCESS,
                payload: res.data['key']
            });








        } catch (err) {
            dispatch({
                type: SYNC_UPLOAD_FAIL
            });


        }






};



export const set_upload_null = (value) => async dispatch => {

      try{
            dispatch({
                type: UPLOAD_NULL_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: UPLOAD_NULL_FAIL
            });
        }



};