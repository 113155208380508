import React, {useState, useEffect} from 'react';



import { get_company_setting } from '../../actions/holding';

import { update_company_setting } from '../../actions/holding';

import { create_company_setting } from '../../actions/holding';

import { delete_company_setting } from '../../actions/holding';

import { load_companysetting_null } from '../../actions/holding';



import { connect } from 'react-redux';





import Snackbar from '@mui/material/Snackbar';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';


import UserDropdown from "./UserDropdown";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import DeleteIcon from '@mui/icons-material/Delete'

const CompanySetting = ({get_company_setting, company_setting, update_company_setting, load_companysetting, load_companysetting_null, create_company_setting, delete_company_setting, user_dropdown, master_dropdown}) => {

const [dropdown, setDropdown] = React.useState(null);

const [open, setOpen] = React.useState(false);

const [msg, setMsg] = React.useState('');
const [type, setType] = React.useState('');

  const handleClick = (message,severity) => {
    setMsg(message);
    setType(severity);


    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

 const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


useEffect( () => {

get_company_setting(user_dropdown);



},[]);

useEffect( () => {

get_company_setting(user_dropdown);



},[user_dropdown]);

useEffect( () => {


if(load_companysetting=='updated'){

get_company_setting(user_dropdown);

handleClick('Succesfully Updated the row','success') ;

}

if(load_companysetting=='uploaded') {

get_company_setting(user_dropdown);

handleClick('Succesfully Uploaded the file','success') ;

}


if(load_companysetting=='added') {

get_company_setting(user_dropdown);

handleClick('Succesfully Added the row','success') ;

}

if(load_companysetting=='deleted') {

get_company_setting(user_dropdown);

handleClick('Succesfully Deleted the row','success') ;

}



if(load_companysetting=='already_exist') {

get_company_setting(user_dropdown);

handleClick('Company that you are trying to add already exist','error') ;

}


if(load_companysetting !='load') {

load_companysetting_null();

}





},[load_companysetting]);



const fetch_data = async (filter_value) => {

 setDropdown(filter_value)

    };



const add_peers = (dropdown,user_dropdown) => {



    if(dropdown != null){


    create_company_setting(user_dropdown,dropdown);



    }

    setDropdown(null);
  };



const delete_company = (dropdown,user_dropdown) => {

    delete_company_setting(user_dropdown, dropdown);
  };





const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 500,
  });





    return (
    <>
    < UserDropdown />
<p style={{'fontSize':'11px', 'color':'blue'}}> Company corresponding to which Peers are being saved  </p>

<h4> Add Peers </h4>

          <div style={{'display':'flex', 'marginTop':'20px'}} >

          <Autocomplete
      disablePortal
      filterOptions={filterOptions}
      getOptionLabel={option => option.company_name}



      id="combo-box-demo"
      options={master_dropdown}
      value = {dropdown}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Company Name" />}
      onChange= {(event,value)=> fetch_data(value) }
    />

    <Button  onClick ={()=> add_peers(dropdown,user_dropdown)} > <span style={{'color':'green','fontWeight':'bold'}}> Add </span> </Button >




          </div>

          <h4>  Peer List </h4>

          { load_companysetting != null ?

          <CircularProgress />

          :

          <>
          {company_setting == null ? null :

          <>

           {
     company_setting.map(

           (content, i) =>{

           return(

           <div style={{'display':'flex'}}>

        <p style={{'width':'150px'}}>
        {content['peer_company_name']}
        </p>

       <IconButton onClick ={()=> delete_company(content['peer_company_name'],user_dropdown)}  aria-label="delete">
        <DeleteIcon />
       </IconButton>


        </div>


           )

           }

)

}

</>

}

          </>
          }






<p style={{'fontSize':'11px', 'color':'grey'}}> <InfoOutlinedIcon sx={{'paddingTop':'10px'}}/>  To add any new company (for which we need to create a peer set) - Toggle to Company button

     </p>


     <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Note archived"
        action={action}
      >
 <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
         {msg}
 </Alert>

  </ Snackbar>

    </>
    )


};






const mapStateToProps = state => ({
    company_setting: state.holding.company_setting,
    load_companysetting: state.holding.load_companysetting,
    user_dropdown: state.holding.user_dropdown,
    master_dropdown: state.holding.master_dropdown


});

export default connect(mapStateToProps,{get_company_setting,update_company_setting, load_companysetting_null, create_company_setting, delete_company_setting})(CompanySetting);

