import React, {useState, useEffect} from 'react';




import { connect } from 'react-redux';


import Snackbar from '@mui/material/Snackbar';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import DeleteIcon from '@mui/icons-material/Delete'


const CompanyUpdate = ({  master_dropdown, username_active}) => {

const [company, setCompany] = useState([{'company_name':'Please Wait'}]);

const [loadcompany, setLoadcompany] = useState(null);

const create_company_update = (dropdown) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ company_obj: dropdown, user_name: username_active })
    };

    fetch('https://transcriptanalyser.com/admin/create-company-update', requestOptions)
      .then(results => results.json())
      .then(data => {
        setLoadcompany(data['key']);
      });
  };


const delete_company_update = (dropdown) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ comp_name: dropdown, user_name:username_active })
    };

    fetch('https://transcriptanalyser.com/admin/deleted-company-update', requestOptions)
      .then(results => results.json())
      .then(data => {
        setLoadcompany(data['key']);
      });
  };

const get_company_update = () => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_name:username_active })
    };

    fetch('https://transcriptanalyser.com/admin/company-update', requestOptions)
      .then(results => results.json())
      .then(data => {
        setCompany(data['key']);
      });
  };


const [dropdown, setDropdown] = React.useState(null);

const [open, setOpen] = React.useState(false);

const [msg, setMsg] = React.useState('');
const [type, setType] = React.useState('');

  const handleClick = (message,severity) => {
    setMsg(message);
    setType(severity);


    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

 const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


useEffect( () => {

 get_company_update();

},[]);

useEffect( () => {


if(loadcompany=='updated'){

get_company_update();

handleClick('Succesfully Updated the row','success') ;

}

if(loadcompany=='uploaded') {

get_company_update();

handleClick('Succesfully Uploaded the file','success') ;

}


if(loadcompany=='added') {

get_company_update();

handleClick('Succesfully Added the row','success') ;

}

if(loadcompany=='deleted') {

get_company_update();

handleClick('Succesfully Deleted the row','success') ;

}

if(loadcompany !='load') {

setLoadcompany(null)

}


if(loadcompany=='already_exist') {

get_company_update();

handleClick('Company that you are trying to add already exist','error') ;

}





},[loadcompany]);



const fetch_data = async (filter_value) => {

 setDropdown(filter_value)

    };



const add_company = (dropdown) => {





    if(dropdown != null){
    setLoadcompany('load');

    create_company_update(dropdown);

    }

    setDropdown(null);

  };



const delete_company = (dropdown) => {
    setLoadcompany('load');
    delete_company_update(dropdown);
  };




const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 500,
  });




    return (
    <>

<p style={{'fontSize':'11px', 'color':'blue'}}> Company to be tracked in App </p>

  <h4> Add Company </h4>

          <div style={{'display':'flex', 'marginTop':'20px'}} >

          <Autocomplete
      disablePortal
      filterOptions={filterOptions}
      getOptionLabel={option => option.company_name}



      id="combo-box-demo"
      options={master_dropdown}
      value = {dropdown}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Company Name" />}
      onChange= {(event,value)=> fetch_data(value) }
    />

    <Button  onClick ={()=> add_company(dropdown)} > <span style={{'color':'green','fontWeight':'bold'}}> Add </span> </Button >




          </div>

          <h4>  Company List </h4>

          { loadcompany != null ?

          <CircularProgress />

          :

          <>
          {company == null ? null :

          <>

           {
     company.map(

           (content, i) =>{

           return(

           <div style={{'display':'flex'}}>

        <p style={{'width':'150px'}}>
        {content['company_name']}
        </p>

       <IconButton onClick ={()=> delete_company(content['company_name'])}  aria-label="delete">
        <DeleteIcon />
       </IconButton>


        </div>


           )

           }

)

}


</>

}

          </>
          }



<p style={{'fontSize':'11px', 'color':'grey'}}> <InfoOutlinedIcon sx={{'paddingTop':'10px'}}/> To add peers corresponding to these companies, toggle to PEER button on top right part   </p>
     <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Note archived"
        action={action}
      >
 <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
         {msg}
 </Alert>

  </ Snackbar>

    </>
    )


};






const mapStateToProps = state => ({

    master_dropdown: state.admin.master_dropdown


});

export default connect(mapStateToProps,null)(CompanyUpdate);

