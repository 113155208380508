import React, { useState, useEffect } from "react";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { MenuProps, useStyles, options } from "./utils";

import { news_dropdown1_value } from '../../actions/news';

import { connect } from 'react-redux';

import { get_news_data1 } from '../../actions/news';

import { set_news_data1_null } from '../../actions/news';

import { set_upvote_null } from '../../actions/news';

const NewsDropdown9 = ({news_dropdown1_value,news_dropdown1,news_list, get_news_data1, set_news_data1_null, upvote_status, set_upvote_null }) => {





  const classes = useStyles();



  const [selected, setSelected] = useState([]);
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleChange = (event) => {

    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      return;
    }
    setSelected(value);





  };


useEffect( () => {


set_news_data1_null();

get_news_data1('multi-sector','none',selected);



},[selected]);


useEffect( () => {


if (upvote_status == 'success'){


get_news_data1('multi-sector','none',selected);
set_upvote_null(null);


}



},[upvote_status]);


  return (
    <FormControl  className={classes.formControl}>
      <InputLabel id="mutiple-select-label">Multiple Select</InputLabel>
      <Select
        labelId="mutiple-select-label"
        multiple
        value={selected}
        onChange={handleChange}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        <MenuItem
          value="all"
          classes={{
            root: isAllSelected ? classes.selectedAll : ""
          }}
        >
          <ListItemIcon>
            <Checkbox
              classes={{ indeterminate: classes.indeterminateColor }}
              checked={isAllSelected}
              indeterminate={
                selected.length > 0 && selected.length < options.length
              }
            />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.selectAllText }}
            primary="Select All"
          />
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            <ListItemIcon>
              <Checkbox checked={selected.indexOf(option) > -1} />
            </ListItemIcon>
            <ListItemText primary={option} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}



const mapStateToProps = state => ({
    news_dropdown1: state.news.news_dropdown1,
    news_list: state.news.news_list,
    upvote_status: state.news.upvote_status


});


export default connect(mapStateToProps,{news_dropdown1_value, get_news_data1, set_news_data1_null, set_upvote_null})(NewsDropdown9);
