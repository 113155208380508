import React , {useEffect,useState} from 'react';

import Grid from '@mui/material/Grid';

import { connect } from 'react-redux';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import ReportHome from "../components/mailchimp/ReportHome";
import SendCampaign from "../components/mailchimp/SendCampaign";


const MailChimp = () => {

const [ menu, setMenu ] = useState('report');

const handleAlignment = (event, newAlignment) => {
    setMenu(newAlignment);

  };


    return (
    <>
    {/*
    <div  style= {{ 'display': 'flex', 'justifyContent': 'right'}}   >

    <ToggleButtonGroup
      value= {menu}
      onChange={handleAlignment}
      color="primary"
      exclusive
    >

      <ToggleButton  value="report">Report Analytics</ToggleButton>
      <ToggleButton  value="send">Send Campaign</ToggleButton>


    </ToggleButtonGroup>
    </div>
    */}

    <div style= {{ 'display': 'flex', 'justifyContent': 'center'}} >





    {menu == 'report' ?

    <ReportHome />

    :

    <SendCampaign />
    }




    </div>

    </>

    )


};


const mapStateToProps = state => ({



});

export default connect(mapStateToProps,null)(MailChimp );

