import React from 'react';

import SideMenu from "./SideMenu";



const Home = () => {



    return (
    <>

< SideMenu />



    </>

    )


};



export default Home;