import React, {useState, useEffect} from 'react';




import { connect } from 'react-redux';



import Snackbar from '@mui/material/Snackbar';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';

import UserDropdown from "./UserDropdown";

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';

import DeleteIcon from '@mui/icons-material/Delete'

import { user_dropdown_value } from '../../actions/admin';

const PeerUpdate = ({  user_dropdown, master_dropdown,username_active,admin_dropdown}) => {

const [peer, setPeer] = useState([{'company_name':'Please Wait'}]);

const [loadpeer, setLoadpeer] = useState(null);

const create_peer_update = (user_dropdown , dropdown) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ peer_obj: dropdown, user_name:username_active, comp_name: user_dropdown  })
    };

    fetch('https://transcriptanalyser.com/admin/create-peer-update', requestOptions)
      .then(results => results.json())
      .then(data => {
        setLoadpeer(data['key']);
      });
  };


const delete_peer_update = (user_dropdown , dropdown) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ comp_name: user_dropdown, user_name:username_active, peer_name: dropdown  })
    };

    fetch('https://transcriptanalyser.com/admin/deleted-peer-update', requestOptions)
      .then(results => results.json())
      .then(data => {
        setLoadpeer(data['key']);
      });
  };

const get_peer_update = (user_dropdown) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ user_name:username_active, comp_name:user_dropdown })
    };

    fetch('https://transcriptanalyser.com/admin/peer-update', requestOptions)
      .then(results => results.json())
      .then(data => {
        setPeer(data['key']);
      });
  };


const [dropdown, setDropdown] = React.useState(null);

const [open, setOpen] = React.useState(false);

const [msg, setMsg] = React.useState('');
const [type, setType] = React.useState('');

  const handleClick = (message,severity) => {
    setMsg(message);
    setType(severity);


    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

 const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


useEffect( () => {

{/*
const initial_value = username_active in  admin_dropdown['company_name'] ? admin_dropdown['company_name'][username_active][0] : 'None' ;


user_dropdown_value(initial_value);

console.log('here');

console.log(initial_value);


get_peer_update(initial_value);

*/}
},[]);

useEffect( () => {

if(user_dropdown != '') {
get_peer_update(user_dropdown);
}



},[user_dropdown]);

useEffect( () => {


if(loadpeer=='updated'){

get_peer_update(user_dropdown);

handleClick('Succesfully Updated the row','success') ;

}

if(loadpeer=='uploaded') {

get_peer_update(user_dropdown);

handleClick('Succesfully Uploaded the file','success') ;

}


if(loadpeer=='added') {

get_peer_update(user_dropdown);

handleClick('Succesfully Added the row','success') ;

}

if(loadpeer=='deleted') {

get_peer_update(user_dropdown);

handleClick('Succesfully Deleted the row','success') ;

}



if(loadpeer=='already_exist') {

get_peer_update(user_dropdown);

handleClick('Company that you are trying to add already exist','error') ;

}


if(loadpeer !='load') {

setLoadpeer(null);

}





},[loadpeer]);



const fetch_data = async (filter_value) => {

 setDropdown(filter_value)

    };



const add_peers = (dropdown,user_dropdown) => {



    if(dropdown != null){


    create_peer_update(user_dropdown,dropdown);



    }

    setDropdown(null);
  };



const delete_peer = (dropdown,user_dropdown) => {

    delete_peer_update(user_dropdown, dropdown);
  };





const filterOptions = createFilterOptions({
    matchFrom: 'any',
    limit: 500,
  });





    return (
    <>
   < UserDropdown username_active= {username_active}  />
<p style={{'fontSize':'11px', 'color':'blue'}}> Company corresponding to which Peers are being saved  </p>

<h4> Add Peers </h4>

          <div style={{'display':'flex', 'marginTop':'20px'}} >

          <Autocomplete
      disablePortal
      filterOptions={filterOptions}
      getOptionLabel={option => option.company_name}



      id="combo-box-demo"
      options={master_dropdown}
      value = {dropdown}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="Company Name" />}
      onChange= {(event,value)=> fetch_data(value) }
    />

    <Button  onClick ={()=> add_peers(dropdown,user_dropdown)} > <span style={{'color':'green','fontWeight':'bold'}}> Add </span> </Button >




          </div>

          <h4>  Peer List </h4>

          { loadpeer != null ?

          <CircularProgress />

          :

          <>
          {peer == null ? null :

          <>

           {
     peer.map(

           (content, i) =>{

           return(

           <div style={{'display':'flex'}}>

        <p style={{'width':'150px'}}>
        {content['company_name']}
        </p>

       <IconButton onClick ={()=> delete_peer(content['company_name'],user_dropdown)}  aria-label="delete">
        <DeleteIcon />
       </IconButton>


        </div>


           )

           }

)

}

</>

}

          </>
          }






<p style={{'fontSize':'11px', 'color':'grey'}}> <InfoOutlinedIcon sx={{'paddingTop':'10px'}}/>  To add any new company (for which we need to create a peer set) - Toggle to Company button

     </p>


     <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Note archived"
        action={action}
      >
 <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
         {msg}
 </Alert>

  </ Snackbar>

    </>
    )


};






const mapStateToProps = state => ({
    // company dropdown on peer page
    admin_dropdown: state.admin.admin_dropdown,
    user_dropdown: state.admin.user_dropdown,
    master_dropdown: state.admin.master_dropdown


});

export default connect(mapStateToProps, null)(PeerUpdate);

