import React from 'react';


//import TweetEmbed from 'react-tweet-embed';
import {  useParams } from 'react-router-dom';


const SingleTweet = () => {

const { id } = useParams();

    return (
    <>

{/*<TweetEmbed  tweetId={id} options={{theme: 'dark'}}  placeholder={'loading'}  /> */}






    </>

    )


};



export default SingleTweet;




