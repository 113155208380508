import React, {useState,useEffect } from 'react';



import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { connect } from 'react-redux';

import Button from '@mui/material/Button';





import { Link, Redirect,NavLink } from 'react-router-dom';

import MaterialTable from "material-table";
//import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';


const HistoricalMeeting = ({historical_meeting, meeting_child}) => {



const rows = historical_meeting;





  return (
  <>


  {rows == null ? null :
<>




<MaterialTable
        size="small"
          columns={[



        { title: "company_name", field: "company_name",cellStyle: {
        minWidth: 115
      } },

       { title: "Heading", field: "Heading",cellStyle: {
        minWidth: 205
      } },



      { title: "Newsdate", field: "Newsdate",cellStyle: {
        minWidth: 105
      } },

       { title: "pdf_link" ,cellStyle: {
        minWidth: 85
      },
         render: rowData => <a href = {rowData['Attachmenturl']} target="_blank" > Link </a>
       },


       { title: "Who is Meeting",cellStyle: {
        minWidth: 185
      },
         render: rowData =><>

               {
     meeting_child[rowData['meeting_id']].map(

           (content, i) =>{

           return(



        <span >
        {content['firm_name']} ,
        </span>





           )

           }

)

}

           </>
       },




         { title: "Inputed Meeting Date", field: "meeting_event_date",cellStyle: {
        minWidth: 110
      } },


           { title: "Meeting Organiser", field: "meeting_organiser",cellStyle: {
        minWidth: 110
      } },

         { title: "Meeting Format", field: "meeting_format",cellStyle: {
        minWidth: 110
      } },

        { title: "Event Type", field: "event_type",cellStyle: {
        minWidth: 90
      } },





          ]}
          data={historical_meeting}
          title="Who is Meeeting Whom ?"

          options={{


        exportButton: false,
        search: true,
        paging: false,
        padding: 'dense',
        headerStyle:{fontWeight:'bold'},
        rowStyle: {
      fontSize: 14,
    }
      }}

        />

 </>
}





   </>
  );
}


const mapStateToProps = state => ({
    historical_meeting: state.meeting_utility.historical_meeting,
    meeting_child: state.meeting_utility.meeting_child


});

export default connect(mapStateToProps,null)(HistoricalMeeting);
