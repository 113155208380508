import React , {useEffect,useState} from 'react';

import Grid from '@mui/material/Grid';

import { connect } from 'react-redux';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import CancelIcon from '@mui/icons-material/Cancel';

import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';

import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';



import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

import Autocomplete from '@mui/material/Autocomplete';


const ReportHome = () => {

const [counter, setCounter] = useState(0);


const [expanded, setExpanded] = React.useState(false);

const handleChange7 = (panel) => (event, isExpanded) => {
    setDropdown('All')
    setExpanded(isExpanded ? panel : false);
  };

const [open, setOpen] = React.useState(false);


   const  handleClickOpen = (index) => {

   setDetail([{'tag_name':'Please Wait','open_no':'','open_perc':'','members':[{'email':'wait','open':1}]}
                                     ])


   setActive(index);


    setOpen(true);

    console.log(JSON.stringify({'tag_list': campaign[index]['tag_list'], 'id_list': campaign[index]['id'],
                         'open_list':campaign[index]['open1'],'total_list':campaign[index]['total1'],
                         'open_rate_list':campaign[index]['open_rate1']})) ;



    fetch('https://transcriptanalyser.com/mailchimp/report-detail',
    {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
       "Access-Control-Allow-Origin": "*"
    },
    method: "POST",
    body: JSON.stringify({tag_list: campaign[index]['tag_list'], id_list: campaign[index]['id'],
                          open_list :campaign[index]['open1'], total_list:campaign[index]['total1'],
                         open_rate_list:campaign[index]['open_rate1']})
    }
    )
      .then(results => results.json())
      .then(data => {
        setDetail(data['key']);
      });





  };

  const handleClose = () => {
    setOpen(false);
  };


  const handleEmail = () => {
    setOpen(false);
  };

const [active, setActive] = useState(0);

const [campaign, setCampaign] = useState([{'web_id':'please wait','tag_list':['a']}]);

const [detail, setDetail] = useState([{'tag_name':'Please Wait','open_no':'','open_perc':'','members':[{'email':'wait','open':1}]}
                                     ]);

useEffect( () => {

fetch('https://transcriptanalyser.com/mailchimp/report-home',
    {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
       "Access-Control-Allow-Origin": "*"
    },
    method: "POST",
    body: JSON.stringify({counter:0})
    }
    )
      .then(results => results.json())
      .then(data => {
        console.log(data['key']);

        setCampaign(data['key']);
      });


    setCounter(counter + 1);







},[]);

const theme = useTheme();

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

 const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };





  const handleMore = () => {

    setOpen9(true);





    fetch('https://transcriptanalyser.com/mailchimp/report-home',
    {
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
       "Access-Control-Allow-Origin": "*"
    },
    method: "POST",
    body: JSON.stringify({counter:counter})
    }
    )
      .then(results => results.json())
      .then(data => {

        setCampaign(campaign.concat(data['key']));
      });

    setCounter(counter + 1);


  };

  const [open9, setOpen9] = React.useState(false);


const handleClose9 = () => {

    setOpen9(false);
  };


  const [dropdown, setDropdown] = React.useState('All');

  const change_dropdown = async (value,m) => {


      setDropdown(value);

}



    return (

   <div >
   {/*
      <FormControl sx={{minWidth: {
      sx: 0.9, // 100%
      sm: 300,
      md: 550,} }}>
        <label>
          Filter by Tags:
       </label>
       <br/>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={<OutlinedInput id="select-multiple-chip" label="Chip" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value}  />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {names.map((name) => (
            <MenuItem key={name} value={name}>
              <Checkbox checked={personName.indexOf(name) > -1} />
              <ListItemText primary={name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
*/}



<h5 style={{'color':'blue'}}> Mail Analytics </h5>

{campaign[0]['web_id']=='please wait' ? <div style={{
        'margin': '50px 0',
       'margin-bottom': '20px',
      'padding': '30px 50px',
      'text-align': 'center',
    'border-radius': '4px',}}><CircularProgress /> </div> : <> </>}

       {
     campaign.map(

           (content, i) =>{

           return(

           <div style={{'display':'flex'}}>



        <Card variant="outlined" sx={{border: '1px solid blue',mb:1, minWidth: {
      sx: 1.0, // 100%
      sm: 250,
      md: 550,},
       maxWidth: {
      sx: 1.0, // 100%
      sm: 250,
      md: 550,}}}>
      <CardContent>
      <div style= {{'display': 'flex'}}>
      {/*
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
         {content['campaign_name']} ({content['web_id']})
        </Typography>
        */}

        <Typography sx={{ fontSize: 14 }}  gutterBottom>
       <strong>  {content['campaign_subject']} </strong>
        </Typography>
        <Typography sx={{  fontSize: 11, ml:'auto' }} color="text.secondary" >
          {content['create_time']}
        </Typography>
      </div>

      <Typography sx={{ mb: 1, fontSize: 14,mt:1  }} style={{color:"#00adb5"}} >
         Sent to following tag -
        </Typography>

          {
          content['tag_list'].map(

           (content, i) =>{return(<Chip label={content} variant="outlined" />)}
           )
           }
           {/*
      <Typography sx={{mt:2  }} variant="body2">
 {content['campaign_subject']}
  <br />
    </Typography>
    */}


    <Typography style={{color:"#F865AE"}} sx={{mt:2  }} variant="body2">
  Total Recepeint - {content['total']}
  <br/>
  Opened - {content['open']}
  <br />


    </Typography>

    <br/>

    <Typography sx={{fontSize:12}}>
    <strong >
   Opened rate - {content['open_rate']} %
   </strong>
   </Typography>










      </CardContent>
      <CardActions>
        <Button variant="outlined" size="small" onClick={() => handleClickOpen(i)}>View Tag-wise Report</Button>

      </CardActions>
    </Card>




        </div>


           )

           }

)

}

<div style= {{ 'display': 'flex', 'justifyContent': 'center'}}>


{counter < 4 ? <Button align="center" variant="outlined" size="small" onClick={() => handleMore()}>View More </Button>
 : <> </>
}

<Snackbar open={open9} autoHideDuration={7000} onClose={handleClose9}>
        <Alert onClose={handleClose9} severity="info" sx={{ width: '100%' }}>
          Please Wait Loading more Data !
        </Alert>
      </Snackbar>





</div>

  <Dialog open={open} onClose={handleClose}>

        <DialogTitle>Tag Wise Analytics</DialogTitle>



        <DialogContent>

          <DialogContentText>
           <Typography  sx={{mt:1 ,fontSize:11 }} style={{color:"blue"}} variant="body2">
           <strong >
  Total Recipient - {campaign[active]['total']} | Opened - {campaign[active]['open']}
   </strong>



    </Typography>



    <Typography style={{color:"#F865AE"}} sx={{fontSize:11,mt:1}}>

   Opened rate - {campaign[active]['open_rate']} %

   </Typography>
           </DialogContentText>
 <p> </p>

 {detail[0]['tag_name']=='Please Wait' ? <div style={{
        'margin': '50px 0',
       'margin-bottom': '20px',
      'padding': '30px 50px',
      'text-align': 'center',
    'border-radius': '4px',}}><CircularProgress /> </div> : <> </>}



         <div>

         <div style= {{'display':'flex'}} >
         <Typography sx={{ width: '40%', flexShrink: 0,fontSize:12,fontWeight:'bold' }}>
            Tag Name
          </Typography>
          <Typography   sx={{ width: '20%', fontSize:12,fontWeight:'bold' }}>
           Total</Typography>
          <Typography   sx={{ width: '20%', fontSize:12 ,fontWeight:'bold' }}>
            Open   </Typography>
            <Typography   sx={{ width: '20%', fontSize:12,fontWeight:'bold' }}>
            <span style={{fontWeight:'bold'}}> Rate%</span> </Typography>
        </div>
          {
     detail.map(

           (content, i) =>{

           return(
            <Accordion expanded={expanded === 'panel' + i} onChange={handleChange7('panel'+i)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: '40%', flexShrink: 0,fontSize:14 }}>
            {content['tag_name']}
          </Typography>
          <Typography   sx={{ width: '20%', fontSize:12 }}>
           <span style={{color:"#A9A9A9", fontWeight:'bold'}}> {/*Unique Open   {content['open']} /  {content['total']} */} &nbsp; &nbsp; {content['total']} </span> </Typography>
          <Typography   sx={{ width: '20%', fontSize:12 }}>
           <span style={{color:"#A9A9A9", fontWeight:'bold'}}> {/*Unique Open   {content['open']} /  {content['total']} */} &nbsp; &nbsp; {content['open']}  </span> </Typography>
            <Typography   sx={{ width: '20%', fontSize:12 }}>
            <span style={{color:"#F865AE"}}> {/*Open Rate- */} &nbsp; &nbsp;    {content['open_rate']}  </span> </Typography>
        </AccordionSummary>
        <AccordionDetails>
        {content['multiple_tag'] == 'yes' ?
        <div style={{'display':'flex','justifyContent': 'space-between'}}>
       {
       dropdown == 'All' ?
       <Typography> <span style={{color:"#A9A9A9", fontWeight:'bold','float':'right'}}> Open &nbsp; &nbsp; {content['open']}  </span> </Typography>
       :
      <Typography> <span style={{color:"#A9A9A9", fontWeight:'bold','float':'right'}}> Open &nbsp; &nbsp; <>{typeof content['new_obj'] != "undefined" || typeof content['new_obj']?.dropdown != "undefined" ? <> {content['new_obj'][dropdown]?.['open']} </>  : <></>} </>   </span> </Typography>

       }
        <Autocomplete
     style={{'backgroundColor':'#FFFFFF'}}
     disableClearable
      disablePortal
      value={dropdown}
      size="small"
      id="combo-box-demo"
      options={content['dropdown_list']}
      sx={{ width: 240 }}
      onChange= {(event,value)=> change_dropdown(value) }
      renderInput={(params) => <TextField {...params} label="Filter" />}
    />
    </div>
    : <></>
    }

    { dropdown == 'All'  ?
        <>
         {
     content['members'].map(

           (member, i) =>{

           return(
           <Typography>
           {member['email_address']} - {member['opens_count']}
          </Typography>
           )
           }
           )
         }
         </>
         :
         <>
         {
         typeof content['new_obj'] != "undefined" || typeof content['new_obj']?.dropdown != "undefined"
         ?
         <>
         {
       content['new_obj'][dropdown]?.members.map(

           (member, i) =>{

           return(
           <Typography>
           {member['email_address']} - {member['opens_count']}
          </Typography>
           )
           }
           )
         }
         </>
         :
         <></>
         }
         </>
         }

        </AccordionDetails>
      </Accordion>
           )
           }
           )
           }


    </div>



          {/*
<FormControlLabel control={<Checkbox
      checked={checked}
      onChange={handleChange5}
      inputProps={{ 'aria-label': 'controlled' }}
    />} label="Include Only Upvoted News" />
    */}

        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>

      </Dialog>



    </div>

    )


};


const mapStateToProps = state => ({



});

export default connect(mapStateToProps,null)(ReportHome );

