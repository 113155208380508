import React, {useEffect} from 'react';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { connect } from 'react-redux';

import { upload_table_value } from '../actions/upload';

import { sync_upload_value } from '../actions/upload';

import { set_upload_null } from '../actions/upload';

import LinearProgress from '@mui/material/LinearProgress';

import Stack from '@mui/material/Stack';

import Alert from '@mui/material/Alert';


import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Typography from '@mui/material/Typography';

const Upload = ({upload_table,upload_table_value,sync_upload_value,set_upload_null,sync_status}) => {

const vertical = 'top';

const horizontal='right';

const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };


const vertical7 ='bottom';

const horizontal7='left';

const [open7, setOpen7] = React.useState(false);

  const handleClick7 = () => {
    setOpen7(true);
  };

  const handleClose7 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen7(false);
  };





useEffect( () => {


upload_table_value();

},[]);

useEffect( () => {


if(sync_status=='fail'){
handleClick();
set_upload_null('neutral');
}

if(sync_status=='success'){
handleClick7();
upload_table_value();
set_upload_null('neutral');
}

},[sync_status]);







const uploadDB = (type,fincode) => {

sync_upload_value(type,fincode);

set_upload_null(null);

}


const rows = upload_table;

    return (
    <>
    {
    sync_status == null ?

      <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
      <p style={{'fontWeight':'bold'}}> Please Wait while  Database is getting synced with the Excel Sheet </p>
      <LinearProgress color="secondary" />
      <LinearProgress color="success" />
      <LinearProgress color="inherit" />
    </Stack>

    :

  <>
   <Typography align="center" variant="h5">Second Quarter 2022 - Syncing Excel & Database </Typography>
 <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell> <span style={{'fontWeight':'bold'}}>Company Name</span></TableCell>
            <TableCell> <span style={{'fontWeight':'bold'}}>Event Date</span></TableCell>

            <TableCell align="left"><span style={{'fontWeight':'bold'}}> Quarter Operational Metric </span></TableCell>
            <TableCell align="left"><span style={{'fontWeight':'bold'}}>Quarter Breakdown  </span></TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.company_name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.company_name}
              </TableCell>

               <TableCell align="left">{row.event_date}</TableCell>
               {
               row.general=="Not Updated" ?
              <TableCell align="left"><span style={{'color':'red'}}> {row.general} </span> <br/>  <Button onClick={() => uploadDB('general',row.fincode)}  size="small"  variant="outlined">Update</Button></TableCell>
              :
               <TableCell align="left"><span style={{'color':'green'}}> {row.general} </span> <br/>  <Button onClick={() => uploadDB('general',row.fincode)}  size="small"  variant="outlined">Re-Update</Button></TableCell>

              }
               {
               row.breakup=="Not Updated" ?
              <TableCell align="left"><span style={{'color':'red'}}> {row.breakup} </span> <br/>  <Button onClick={() =>uploadDB('breakup',row.fincode)}  size="small"  variant="outlined">Update</Button></TableCell>
              :
               <TableCell align="left"><span style={{'color':'green'}}> {row.breakup} </span> <br/>  <Button onClick={() => uploadDB('breakup',row.fincode)}  size="small"  variant="outlined">Re-Update</Button></TableCell>

              }

            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>



    </>

    }

    <Snackbar open={open} autoHideDuration={18000} onClose={handleClose} anchorOrigin={{vertical, horizontal }}
key={vertical + horizontal}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
         Error occured while syncing. Please check the format of values being added. If problem still persist, Contact Admin
        </Alert>
</Snackbar>

<Snackbar open={open7} autoHideDuration={18000} onClose={handleClose7} anchorOrigin={{vertical, horizontal }}
key={vertical7 + horizontal7}>
        <Alert onClose={handleClose7} severity="success" sx={{ width: '100%' }}>
         Succesfully Synced. Total No of rows filled till now is shown in corresponding rows. Contact Admin if any discrepancy
        </Alert>
</Snackbar>

    </>

    )


};

const mapStateToProps = state => ({
    upload_table: state.upload.upload_table,
    sync_status: state.upload.sync_status

});


export default connect(mapStateToProps,{upload_table_value,sync_upload_value,set_upload_null})(Upload);


