import {

NEWS_DATA1_SUCCESS,
NEWS_DATA1_FAIL,
NEWS_NULL_SUCCESS,
NEWS_NULL_FAIL,
DROPDOWN_LIST_NEWS_SUCCESS,
DROPDOWN_LIST_NEWS_FAIL,
NEWS_DROPDOWN1_SUCCESS,
NEWS_DROPDOWN1_FAIL,
UPVOTE_STATUS_SUCCESS,
UPVOTE_STATUS_FAIL,
UPVOTE_NULL_SUCCESS,
UPVOTE_NULL_FAIL,
NEWS_TOGGLE_SUCCESS,
NEWS_TOGGLE_FAIL,

NEWS_DATA2_SUCCESS,
NEWS_DATA2_FAIL,
EMAIL_SECTOR_SUCCESS,
EMAIL_SECTOR_FAIL,

NEWS_EMAIL_SUCCESS,
NEWS_EMAIL_FAIL,

SOURCE_TOGGLE_SUCCESS,
SOURCE_TOGGLE_FAIL,

COMPANY_SOURCE_SUCCESS,
COMPANY_SOURCE_FAIL,
LOAD_COMPANYSOURCE_SUCCESS,
LOAD_COMPANYSOURCE_FAIL,
NULL_COMPANYSOURCE_SUCCESS,
NULL_COMPANYSOURCE_FAIL,


SECTOR_DROPDOWN_SUCCESS,
SECTOR_DROPDOWN_FAIL,

SECTOR_SOURCE_SUCCESS,
SECTOR_SOURCE_FAIL,
LOAD_SECTORSOURCE_SUCCESS,
LOAD_SECTORSOURCE_FAIL,
NULL_SECTORSOURCE_SUCCESS,
NULL_SECTORSOURCE_FAIL,


} from '../actions/types';



const initialState = {
    news_data1: [null],
    news_list: ['a'],
    news_dropdown1: {'name':'All','type':'Sector'},
    upvote_status: null,
    news_toggle: 'company',
    news_data2 : null,
    email_sector: [],
    news_email: null,
    source_toggle:'company',
    company_source: [],
    load_companysource: null,


    sector_dropdown : 'Chemicals',

    sector_source: [],
    load_sectorsource: null,


};


export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
       case NEWS_DATA1_SUCCESS:
            return {
                ...state,
                news_data1: payload

            }

        case NEWS_DATA1_FAIL:
            return {
                ...state

            }


        case NEWS_NULL_SUCCESS:
            return {
                ...state,
                news_data1: [null]

            }

        case NEWS_NULL_FAIL:
            return {
                ...state

            }


        case DROPDOWN_LIST_NEWS_SUCCESS:
            return {
                ...state,
                news_list: payload

            }

        case DROPDOWN_LIST_NEWS_FAIL:
            return {
                ...state

            }

        case NEWS_DROPDOWN1_SUCCESS:
            return {
                ...state,
                news_dropdown1: payload

            }

        case NEWS_DROPDOWN1_FAIL:
            return {
                ...state

            }

        case UPVOTE_STATUS_SUCCESS:
            return {
                ...state,
                upvote_status: payload

            }

        case UPVOTE_STATUS_FAIL:
            return {
                ...state

            }

        case UPVOTE_NULL_SUCCESS:
            return {
                ...state,
                upvote_status: payload

            }

        case UPVOTE_NULL_FAIL:
            return {
                ...state

            }


        case NEWS_TOGGLE_SUCCESS:
            return {
                ...state,
                news_toggle: payload

            }

        case NEWS_TOGGLE_FAIL:
            return {
                ...state

            }


        case NEWS_DATA2_SUCCESS:
            return {
                ...state,
                news_data2: payload

            }

        case NEWS_DATA2_FAIL:
            return {
                ...state

            }

        case EMAIL_SECTOR_SUCCESS:
            return {
                ...state,
                email_sector: payload

            }

        case EMAIL_SECTOR_FAIL:
            return {
                ...state

            }

        case NEWS_EMAIL_SUCCESS:
            return {
                ...state,
                news_email: payload

            }

        case NEWS_EMAIL_FAIL:
            return {
                ...state

            }

        case SOURCE_TOGGLE_SUCCESS:
            return {
                ...state,
                source_toggle: payload

            }

        case SOURCE_TOGGLE_FAIL:
            return {
                ...state

            }


        case COMPANY_SOURCE_SUCCESS:
            return {
                ...state,
                company_source: payload

            }

        case COMPANY_SOURCE_FAIL:
            return {
                ...state

            }


        case LOAD_COMPANYSOURCE_SUCCESS:
            return {
                ...state,
               load_companysource: payload

            }

        case LOAD_COMPANYSOURCE_FAIL:
            return {
                ...state

            }


        case NULL_COMPANYSOURCE_SUCCESS:
            return {
                ...state,
               load_companysource: null

            }

        case NULL_COMPANYSOURCE_FAIL:
            return {
                ...state

            }





        case SECTOR_DROPDOWN_SUCCESS:
            return {
                ...state,
                sector_dropdown: payload

            }

        case SECTOR_DROPDOWN_FAIL:
            return {
                ...state

            }

       case SECTOR_SOURCE_SUCCESS:
            return {
                ...state,
                sector_source: payload

            }

        case SECTOR_SOURCE_FAIL:
            return {
                ...state

            }


        case LOAD_SECTORSOURCE_SUCCESS:
            return {
                ...state,
               load_sectorsource: payload

            }

        case LOAD_SECTORSOURCE_FAIL:
            return {
                ...state

            }


        case NULL_SECTORSOURCE_SUCCESS:
            return {
                ...state,
               load_sectorsource: null

            }

        case NULL_SECTORSOURCE_FAIL:
            return {
                ...state

            }





        default:
            return state


        }


    };

