import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { connect } from 'react-redux';

import { user_dropdown_value } from '../../actions/admin';




const UserDropdown = ({username_active,admin_dropdown,user_dropdown_value,user_dropdown,admin_toggle}) => {


useEffect( () => {



const initial_value = username_active in  admin_dropdown['company_name'] ? admin_dropdown['company_name'][username_active][0] : 'None' ;


user_dropdown_value(initial_value);




},[]);


useEffect( () => {


const initial_value = username_active in  admin_dropdown['company_name'] ? admin_dropdown['company_name'][username_active][0] : 'None' ;


user_dropdown_value(initial_value);


},[admin_dropdown]);


// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = username_active in  admin_dropdown['company_name'] ? admin_dropdown['company_name'][username_active] : ['None'];


const fetch_data = async (value) => {

user_dropdown_value(value);

}








  return (
  <div style={{'marginLeft':'30px','marginTop':'20px'}}>
    <Autocomplete
    getOptionLabel={option => option}
     style={{'backgroundColor':'#FFFFFF'}}
     disableClearable
      disablePortal
      value={user_dropdown}
      size="small"
      id="combo-box-demo"
      options={top100Films}
      sx={{ width: 240 }}
      onChange= {(event,value)=> fetch_data(value) }
      renderInput={(params) => <TextField {...params} label="Company" />}
    />
    </div>
  );
}


const mapStateToProps = state => ({
    admin_dropdown: state.admin.admin_dropdown,
    user_dropdown: state.admin.user_dropdown,
    admin_toggle: state.admin.admin_toggle
});


export default connect(mapStateToProps,{user_dropdown_value})(UserDropdown);