import React , {useEffect,useState} from 'react';

import Grid from '@mui/material/Grid';

import { connect } from 'react-redux';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Typography from '@mui/material/Typography';

import CompanyUpdate from "../components/accountadmin/CompanyUpdate";
import PeerUpdate from "../components/accountadmin/PeerUpdate";

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { get_admin_dropdown } from '../actions/admin';

import AdminToggle from "../components/accountadmin/AdminToggle";


const AccountAdmin = ({get_admin_dropdown,admin_toggle}) => {

const [currentuser, setCurrentuser] = useState('');

const [user, setUser] = useState([{'company_name':'Please Wait'}]);

const get_account_data = () => {

    fetch('https://transcriptanalyser.com/admin/user-list')
      .then(results => results.json())
      .then(data => {
        setUser(data['key']);
      });
  };

const [open, setOpen] = React.useState(false);

const [toggle, setToggle] = React.useState('company');


useEffect( () => {
 get_account_data();

 get_admin_dropdown();

},[]);


  const handleClickOpen = (username) => {
    setCurrentuser(username);
    setOpen(true);
  };

  const handleClose = () => {

    setOpen(false);

  };

  const handleAlignment = (event, newAlignment) => {
    setToggle(newAlignment);

    get_admin_dropdown();




  };




    return (
    <>


    <div style= {{ 'display': 'flex', 'justifyContent': 'center'}} >

   <div>

     {
     user.map(

           (content, i) =>{

           return(
           <>
           <p> </p>
           <Card sx={{ minWidth: 275 }}>
      <CardContent>
      <Typography sx={{ fontSize: 14 }} color="text.primary" gutterBottom>
          {content['Username']}
      </Typography>
        <Typography sx={{ fontSize: 11 }} color="text.secondary" gutterBottom>
           {content['email']}
        </Typography>
        <Typography variant="h5" component="div">

        </Typography>
        {
        /*

        <Typography sx={{ fontSize: 11, color: 'blue' }} variant="body2">
         Laxmi Organics, Infosys

        </Typography>
        */
        }
      </CardContent>
      <CardActions>
        <Button variant= "outlined" size="small" onClick={()=>handleClickOpen(content['Username'])}>Modify Company / Peers</Button>
      </CardActions>
    </Card>
    </>


           )

           }
     )

     }

     </div>
     </div>

     <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Username: {currentuser}</DialogTitle>
        <DialogContent>
          <DialogContentText>
     Modify the Company and Peers associated with them which would be used in the SimplyIR app
          </DialogContentText>

          {/*
          <div  style={{  'float':'right','marginRight':'15px','marginLeft':'auto','marginTop':'-10px'}} >
    <ToggleButtonGroup
      value={toggle}
      onChange={handleAlignment}
      color="secondary"

      exclusive


    >
      <ToggleButton  value="sector">Peer :</ToggleButton>

      <ToggleButton  value="company">Company</ToggleButton>



    </ToggleButtonGroup>
    </div>
    */
    }

    <AdminToggle  />
         {admin_toggle == 'company' ?
         < CompanyUpdate username_active= {currentuser} />

          :

         < PeerUpdate username_active= {currentuser} />

          }
        </DialogContent>
        <DialogActions>

          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>



    </>

    )


};


const mapStateToProps = state => ({

 admin_toggle: state.admin.admin_toggle

});

export default connect(mapStateToProps,{get_admin_dropdown})(AccountAdmin );

