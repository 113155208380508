import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { news_dropdown1_value } from '../../actions/news';

import { connect } from 'react-redux';

const NewsDropdown1 = ({news_dropdown1_value,news_dropdown1,news_list}) => {

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top
const top100Films = news_list;

const fetch_data = async (value) => {

news_dropdown1_value(value);

}


  return (
  <div style={{'marginLeft':'30px','marginTop':'20px'}}>
    <Autocomplete
     style={{'backgroundColor':'#FFFFFF'}}
      groupBy={(option) => option.type}
     disableClearable
      disablePortal
      value={news_dropdown1}
      getOptionLabel={(option) => option.name}
      size="small"
      id="combo-box-demo"
      options={top100Films}
      sx={{ width: 240 }}
      onChange= {(event,value)=> fetch_data(value) }
      renderInput={(params) => <TextField {...params} label="Sector or Company Name" />}
    />
    </div>
  );
}


const mapStateToProps = state => ({
    news_dropdown1: state.news.news_dropdown1,
    news_list: state.news.news_list


});


export default connect(mapStateToProps,{news_dropdown1_value})(NewsDropdown1);