import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  formControl: {

    //margin: theme.spacing(1),
    marginLeft:'40px',
    width: 300
  },
  indeterminateColor: {
    color: "#f50057"
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center"
  },
  variant: "menu"
};

const options = [
"Abrasives",
 "Agri",
 "Alcohol",
 "Automobile & Ancillaries",
 "Aviation",
 "Bank",
 "Capital Goods",
 "Chemicals",
 "Construction Materials",
 "Consumer Durables",
 "Crude Oil",
 "Diamond  &  Jewellery",
 "Diversified",
 "Electricals",
 "FMCG",
 "Finance",
 "Gas Transmission",
 "Healthcare",
 "Hospitality",
 "IT",
 "Inds. Gases & Fuels",
 "Infrastructure",
 "Iron & Steel",
 "Logistics",
 "Media & Entertainment",
 "Mining",
 "Miscellaneous",
 "Non - Ferrous Metals",
 "Plastic Products",
 "Power",
 "Ratings",
 "Realty",
 "Retailing",
 "Ship Building",
 "Telecom",
 "Textile",
 "Trading"
];

export { useStyles, MenuProps, options };
