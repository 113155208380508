import axios from 'axios';

import {
     ACTIVE_DASHBOARD_SUCCESS,
     ACTIVE_DASHBOARD_FAIL,

     CURRENT_MEETING_SUCCESS,
     CURRENT_MEETING_FAIL,
     HISTORICAL_MEETING_SUCCESS,
     HISTORICAL_MEETING_FAIL,
     MEETING_DROPDOWN_SUCCESS,
     MEETING_DROPDOWN_FAIL,
     MEETING_LOAD_SUCCESS,
     MEETING_LOAD_FAIL,

     MEETING_CHILD_SUCCESS,
     MEETING_CHILD_FAIL,

     MEETING_TOGGLE_SUCCESS,
     MEETING_TOGGLE_FAIL,

     MEETING_PPT_SUCCESS,
     MEETING_PPT_FAIL
    } from './types';


const getCookies = () =>
  document.cookie.split(';').reduce((cookies, item) => {
    const [name, value] = item.split('=');
    cookies[name] = value;
    return cookies;
  }, {});




export const get_meeting_master= () => async dispatch => {




        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/get-meeting-master`);



            dispatch({
                type: CURRENT_MEETING_SUCCESS,
                payload: res.data['key'][0]
            });


            dispatch({
                type: HISTORICAL_MEETING_SUCCESS,
                payload: res.data['key'][1]
            });




        } catch (err) {
            dispatch({
                type: CURRENT_MEETING_FAIL
            });

            dispatch({
                type: HISTORICAL_MEETING_FAIL
            });


        }


};




export const get_meeting_dropdown= () => async dispatch => {




        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/get-meeting-dropdown`);



            dispatch({
                type: MEETING_DROPDOWN_SUCCESS,
                payload: res.data['key']
            });





        } catch (err) {
            dispatch({
                type: MEETING_DROPDOWN_FAIL
            });



        }


};


export const add_meeting_member = (dropdown_name,dropdown, key) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ dropdown_name:dropdown_name, dropdown:dropdown, key:key });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/add-meeting-member`,body,config );

            const payload = res.data['key'] ;



            dispatch({
                type: MEETING_LOAD_SUCCESS,
                payload: payload
            });




        } catch (err) {
            dispatch({
                type: MEETING_LOAD_FAIL
            });


        }



};



export const update_meeting_load= (value) => async dispatch => {

      try{
             dispatch({
                type: MEETING_LOAD_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: MEETING_LOAD_FAIL
            });
        }



};




export const get_meeting_child= () => async dispatch => {




        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/get-meeting-child`);



            dispatch({
                type: MEETING_CHILD_SUCCESS,
                payload: res.data['key']
            });

            dispatch({
                type: MEETING_LOAD_SUCCESS,
                payload: null
            });





        } catch (err) {
            dispatch({
                type: MEETING_CHILD_FAIL
            });



        }


};


export const delete_meeting_member = (firm_id,  key) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ firm_id:firm_id,  key:key });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/delete-meeting-member`,body,config );

            const payload = res.data['key'] ;



            dispatch({
                type: MEETING_LOAD_SUCCESS,
                payload: payload
            });




        } catch (err) {
            dispatch({
                type: MEETING_LOAD_FAIL
            });


        }



};



export const update_meeting_detail = (value,  value1, key, checked,organiser, event) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ value:value, value1:value1 , key:key, checked:checked, organiser:organiser, event:event });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/update-meeting-detail`,body,config );

            const payload = res.data['key'] ;



            dispatch({
                type: MEETING_LOAD_SUCCESS,
                payload: payload
            });




        } catch (err) {
            dispatch({
                type: MEETING_LOAD_FAIL
            });


        }



};



export const meeting_toggle_value = (value) => async dispatch => {

      try{
            dispatch({
                type: MEETING_TOGGLE_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: MEETING_TOGGLE_FAIL
            });
        }



};



export const update_active_dashboard= (value) => async dispatch => {

      try{
            dispatch({
                type: ACTIVE_DASHBOARD_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: ACTIVE_DASHBOARD_FAIL
            });
        }



};



export const add_meeting_copy = (data) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ data:data });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/add-meeting-copy`,body,config );

            const payload = res.data['key'] ;



            dispatch({
                type: MEETING_LOAD_SUCCESS,
                payload: payload
            });




        } catch (err) {
            dispatch({
                type: MEETING_LOAD_FAIL
            });


        }



};



export const create_meeting_ppt = (start, end, email) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ start:start, end:end, email:email});

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/create-meeting-ppt`,body,config );

            const payload = res.data['key'] ;


            dispatch({
                type: MEETING_PPT_SUCCESS,
                payload: payload[0]
            });



            dispatch({
                type: MEETING_LOAD_SUCCESS,
                payload: payload[1]
            });




        } catch (err) {
            dispatch({
                type: MEETING_PPT_FAIL
            });


            dispatch({
                type: MEETING_LOAD_FAIL
            });


        }



};



export const update_meeting_ppt= (value) => async dispatch => {

      try{
            dispatch({
                type: MEETING_PPT_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: MEETING_PPT_FAIL
            });
        }



};
