import axios from 'axios';

import {
    CREATE_HOLDING_SUCCESS,
    CREATE_HOLDING_FAIL,
    HOLDING_NULL_SUCCESS,
    HOLDING_NULL_FAIL,

    SETTING_TOGGLE_SUCCESS,
    SETTING_TOGGLE_FAIL,

    USER_SETTING_SUCCESS,
    USER_SETTING_FAIL,
    LOAD_USERSETTING_SUCCESS,
    LOAD_USERSETTING_FAIL,
    NULL_USERSETTING_SUCCESS,
    NULL_USERSETTING_FAIL,


    USER_DROPDOWN_SUCCESS,
    USER_DROPDOWN_FAIL,

    COMPANY_SETTING_SUCCESS,
    COMPANY_SETTING_FAIL,
    LOAD_COMPANYSETTING_SUCCESS,
    LOAD_COMPANYSETTING_FAIL,
    NULL_COMPANYSETTING_SUCCESS,
    NULL_COMPANYSETTING_FAIL,

    HOLDING_DROPDOWN_SUCCESS,
    HOLDING_DROPDOWN_FAIL,

    HOLDING_LOAD_SUCCESS,
    HOLDING_LOAD_FAIL


    } from './types';


const getCookies = () =>
  document.cookie.split(';').reduce((cookies, item) => {
    const [name, value] = item.split('=');
    cookies[name] = value;
    return cookies;
  }, {});






export const create_holding_report= (user) => async dispatch => {



 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ comp_obj:user });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/report/create-holding`,body,config );



            dispatch({
                type: CREATE_HOLDING_SUCCESS,
                payload: res.data['key']
            });






        } catch (err) {
            dispatch({
                type: CREATE_HOLDING_FAIL
            });


        }






};




export const set_holding_null = (value) => async dispatch => {

      try{
            dispatch({
                type: HOLDING_NULL_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: HOLDING_NULL_FAIL
            });
        }



};




export const setting_toggle_value = (value) => async dispatch => {

      try{
            dispatch({
                type: SETTING_TOGGLE_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: SETTING_TOGGLE_FAIL
            });
        }



};


export const get_user_setting= () => async dispatch => {




        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/report/user-setting`);



            dispatch({
                type: USER_SETTING_SUCCESS,
                payload: res.data['key']
            });




        } catch (err) {
            dispatch({
                type: USER_SETTING_FAIL
            });
        }


};




export const update_user_setting= (data_obj ) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ data_obj:data_obj});

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/update-user-setting`,body,config );



            dispatch({
                type: LOAD_USERSETTING_SUCCESS,
                payload: res.data['key']
            });



        } catch (err) {
            dispatch({
                type: LOAD_USERSETTING_FAIL
            });


        }






};



export const load_usersetting_null = () => async dispatch => {

      try{
            dispatch({
                type: NULL_USERSETTING_SUCCESS
            });

           }

       catch (err) {
            dispatch({
                type: NULL_USERSETTING_FAIL
            });
        }



};




export const create_user_setting = (data_obj ) => async dispatch => {



 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ company_obj:data_obj});

        dispatch({
                type: LOAD_USERSETTING_SUCCESS,
                payload: 'load'
            });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/report/create-user-setting`,body,config );



            dispatch({
                type: LOAD_USERSETTING_SUCCESS,
                payload: res.data['key']
            });



        } catch (err) {
            dispatch({
                type: LOAD_USERSETTING_FAIL
            });


        }






};


export const delete_user_setting = (data_obj ) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ comp_name:data_obj});

        dispatch({
                type: LOAD_USERSETTING_SUCCESS,
                payload: 'load'
            });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/report/deleted-user-setting`,body,config );



            dispatch({
                type: LOAD_USERSETTING_SUCCESS,
                payload: res.data['key']
            });



        } catch (err) {
            dispatch({
                type: LOAD_USERSETTING_FAIL
            });


        }






};








export const user_dropdown_value = (value) => async dispatch => {

      try{
            dispatch({
                type: USER_DROPDOWN_SUCCESS,
                payload: value
            });






           }

       catch (err) {
            dispatch({
                type: USER_DROPDOWN_FAIL
            });
        }



};





export const get_company_setting= (user ) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ comp_obj:user});



        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/report/company-setting`,body,config );



            dispatch({
                type: COMPANY_SETTING_SUCCESS,
                payload: res.data['key']
            });





        } catch (err) {
            dispatch({
                type: COMPANY_SETTING_FAIL
            });


        }






};



export const update_company_setting= (data_obj ) => async dispatch => {

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ data_obj:data_obj});

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/update-company-setting`,body,config );



            dispatch({
                type: LOAD_COMPANYSETTING_SUCCESS,
                payload: res.data['key']
            });



        } catch (err) {
            dispatch({
                type: LOAD_COMPANYSETTING_FAIL
            });


        }






};



export const load_companysetting_null = () => async dispatch => {

      try{
            dispatch({
                type: NULL_COMPANYSETTING_SUCCESS
            });

           }

       catch (err) {
            dispatch({
                type: NULL_COMPANYSETTING_FAIL
            });
        }



};




export const create_company_setting = (comp_obj, peer_obj ) => async dispatch => {

 console.log(comp_obj);

 console.log(peer_obj);

 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ comp_obj:comp_obj, peer_obj:peer_obj});

        dispatch({
                type: LOAD_COMPANYSETTING_SUCCESS,
                payload: 'load'
            });



        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/report/create-company-setting`,body,config );



            dispatch({
                type: LOAD_COMPANYSETTING_SUCCESS,
                payload: res.data['key']
            });



        } catch (err) {
            dispatch({
                type: LOAD_COMPANYSETTING_FAIL
            });


        }






};


export const delete_company_setting = (data_obj , comp_obj ) => async dispatch => {





 const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Access-Control-Allow-Origin": "*"
            }
        };


        const body = JSON.stringify({ peer_name:comp_obj , comp_obj : data_obj});

        dispatch({
                type: LOAD_COMPANYSETTING_SUCCESS,
                payload: 'load'
            });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/report/deleted-company-setting`,body,config );



            dispatch({
                type: LOAD_COMPANYSETTING_SUCCESS,
                payload: res.data['key']
            });



        } catch (err) {
            dispatch({
                type: LOAD_COMPANYSETTING_FAIL
            });


        }






};





export const get_holding_dropdown= () => async dispatch => {




        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/report/get-holding-dropdown`);



            dispatch({
                type: HOLDING_DROPDOWN_SUCCESS,
                payload: res.data['key']
            });





            dispatch({
                type: USER_DROPDOWN_SUCCESS,
                payload: res.data['key']['company'][0]
            });







        } catch (err) {
            dispatch({
                type: HOLDING_DROPDOWN_FAIL
            });



        }


};



export const update_holding_load= (value) => async dispatch => {

      try{
             dispatch({
                type: HOLDING_LOAD_SUCCESS,
                payload: value
            });

           }

       catch (err) {
            dispatch({
                type: HOLDING_LOAD_FAIL
            });
        }



};








