import {
    TWITTER_DATA_SUCCESS,
    TWITTER_DATA_FAIL

} from '../actions/types';



const initialState = {
    twitter_data:[{'twitter_id':1}]

};


export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case TWITTER_DATA_SUCCESS:
            return {
                ...state,
                twitter_data: payload

            }

        case TWITTER_DATA_FAIL:
            return {
                ...state

            }



        default:
            return state


        }


    };

