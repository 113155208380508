import React, {useState,useEffect } from 'react';



import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { connect } from 'react-redux';

import Button from '@mui/material/Button';





import { Link, Redirect,NavLink } from 'react-router-dom';

import MaterialTable from "material-table";

//import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';


import { get_news_data2 } from '../actions/news';

import IconButton from '@mui/material/IconButton';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import { update_upvote_status } from '../actions/news';

import { set_upvote_null } from '../actions/news';

import CircularProgress from '@mui/material/CircularProgress';

const NewsGeneral = ({news_data2, get_news_data2,update_upvote_status,set_upvote_null,upvote_status}) => {

const [selectedRow, setSelectedRow] = useState(null);

useEffect( () => {


get_news_data2();



},[]);


const rows = news_data2;

const updateUpvote = (news_id,upvote, type ) => {

setSelectedRow(news_id);

update_upvote_status(news_id, upvote, type);


}



useEffect( () => {


if (upvote_status == 'success'){

get_news_data2();
set_upvote_null(null);
setSelectedRow(null);

}



},[upvote_status]);



  return (
  <>


  {rows == null ? null :
<>


<p style={{'width':'120px'}}>.</p>

<MaterialTable
        size="small"

          columns={[




        { title: "title", field: "title",cellStyle: {
        minWidth: 155
      } },

       { title: "description", field: "description",cellStyle: {
        minWidth: 535
      } },

       { title: "news_date", field: "news_date",cellStyle: {
        maxWidth: 65
      } },

      { title: "channel", field: "channel",cellStyle: {
        maxWidth: 55
      } },


        { title: "upvote" , headerStyle: {
            textAlign:'center',
          }, cellStyle: {
        width: 40
      },
         render: rowData =><>




         {rowData['upvote'] == 'yes' ?

   <IconButton onClick={()=> updateUpvote(rowData['id'],'removevote','general')} style={{'float':'right'}} aria-label="delete">
  <ThumbUpIcon color="primary" />

  </IconButton>

  :

 <IconButton  onClick={()=> updateUpvote(rowData['id'],'upvote','general')} style={{'float':'right'}} aria-label="delete">
  <ThumbUpIcon />
</IconButton>





  }

  </>








       },






          ]}
          data={rows}
          title="General News"
           actions={[
        {
          icon: () => <OpenInNewIcon />,
          tooltip: 'open in new tab',
          onClick: (event, rowData) => window.open(rowData.news_link, "_blank")
        }
      ]}

          options={{




        exportButton: false,
        search: true,
        paging: false,
        padding: 'dense',
        headerStyle:{fontWeight:'bold'},
        rowStyle: {
      fontSize: 11,
    },
    maxBodyHeight: "600px"
      }}

        />

 </>
}





   </>
  );
}


const mapStateToProps = state => ({
    news_data2: state.news.news_data2,
    upvote_status: state.news.upvote_status


});

export default connect(mapStateToProps,{get_news_data2,update_upvote_status,set_upvote_null})(NewsGeneral);
