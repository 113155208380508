import React from 'react';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { news_toggle_value } from '../../actions/news';

import { connect } from 'react-redux';

import Button from '@mui/material/Button';



import Autocomplete from '@mui/material/Autocomplete';

import TextField from '@mui/material/TextField';

const NewsToggle = ({news_toggle_value,news_toggle}) => {

const [alignment, setAlignment] = React.useState({ label: 'Company Specific', name: 'company' });

const handleChange = (value) => {
    setAlignment(value);
    news_toggle_value(value.name);
  };


  const top100Films =  [
  { label: 'Company Specific', name: 'company' },
  { label: 'General', name: 'general' }
  ];

    return (
    <>


   <div  style={{  'float':'right','marginRight':'15px','marginLeft':'auto','marginTop':'10px','zIndex':1251}} >


          <Autocomplete

       size="small"
      disableClearable
      disablePortal
      id="combo-box-demo"
      value= {alignment}
      options={top100Films}
      sx={{ width: 300 }}
      renderInput={(params) => <TextField {...params} label="News Type" />}
      onChange= {(event,value)=> handleChange(value) }
    />
    </div>






    </>

    )


};


const mapStateToProps = state => ({
    news_toggle: state.news.news_toggle,


});



export default connect(mapStateToProps,{news_toggle_value})(NewsToggle);