import React, {lazy,Suspense} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';

import Home from './containers/Home';

import { TVChartContainer } from './components/TVChartContainer/index';

import { TVChartContainer1 } from './components/TVChartContainer/index1';

import Zoom from './containers/Zoom';

import Twitter from './containers/Twitter';

import MailChimp from './containers/MailChimp';

import AccountAdmin from './containers/AccountAdmin';

import Feed from './containers/Feed';

import SingleTweet from './containers/SingleTweet';

const GlobalMarket = lazy(() => import('./containers/GlobalMarket'));

const News_Company = lazy(() => import('./containers/News_Company'));

const FeedUpvote = lazy(() => import('./containers/FeedUpvote'));

const MarketBreadth = lazy(() => import('./containers/MarketBreadth'));






const App = () => (
    <Provider store={store}>
        <Router>

                <Switch>
                <Suspense fallback={<div>Loading page..</div>}>

                    <Route exact path='/' component={Home} />

                    <Route exact path='/chart' component={TVChartContainer} />

                    <Route exact path='/chart1/:symbol' component={TVChartContainer1}  />

                    <Route exact path='/zoom/:id' component={Zoom} />

                    <Route exact path='/news-gis/:id' component={News_Company} />

                    <Route exact path='/twitter' component={Twitter} />

                    <Route exact path='/mailchimp' component={MailChimp} />

                    <Route exact path='/account-admin' component={AccountAdmin} />

                    <Route exact path='/feed' component={Feed} />

                    <Route exact path='/globalmarket' component={GlobalMarket} />

                    <Route exact path='/singletweet/:id' component={SingleTweet} />

                    <Route exact path='/feed-upvote' component={FeedUpvote} />

                    <Route exact path='/market-breadth' component={MarketBreadth} />















                </Suspense>





                </Switch>
        {/* <Footer /> */}
        </Router>
    </Provider>
);

export default App;