import React, {useState, useEffect} from 'react';

import MaterialTable from 'material-table';
//import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';



import { get_company_source } from '../../actions/news';

import { update_company_source } from '../../actions/news';

import { create_company_source } from '../../actions/news';

import { delete_company_source } from '../../actions/news';

import { load_companysource_null } from '../../actions/news';



import { connect } from 'react-redux';





import Snackbar from '@mui/material/Snackbar';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const CompanySource = ({get_company_source,company_source, update_company_source, load_companysource, load_companysource_null, create_company_source, delete_company_source}) => {

const [open, setOpen] = React.useState(false);

const [msg, setMsg] = React.useState('');
const [type, setType] = React.useState('');

  const handleClick = (message,severity) => {
    setMsg(message);
    setType(severity);


    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

 const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );


useEffect( () => {

get_company_source();

},[]);

useEffect( () => {


if(load_companysource=='updated'){

get_company_source();

handleClick('Succesfully Updated the row','success') ;

}

if(load_companysource=='uploaded') {

get_company_source();

handleClick('Succesfully Uploaded the file','success') ;

}


if(load_companysource=='added') {

get_company_source();

handleClick('Succesfully Added the row','success') ;

}

if(load_companysource=='deleted') {

get_company_source();

handleClick('Succesfully Deleted the row','success') ;

}

load_companysource_null();



},[load_companysource]);











    return (
    <>
<p style={{'fontSize':'11px', 'color':'blue'}}> Sources to be Blacklisted while fetching Company Specific News </p>

 <MaterialTable
      title="Blacklist"
      columns={[

      { title: 'Source Name', field: 'blacklist' },


      ]}

      data={company_source}

      options={{
        paging:true
        }}

      editable={{
        onRowAdd: newData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {


              if( ("blacklist" in newData)    ) {

              create_company_source(newData);

              }

              else{

              handleClick('At least one value was blank. Fill all values to succesfully create a row','error') ;

              }


              resolve();
            }, 1000)
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve, reject) => {
            setTimeout(() => {

              update_company_source(newData);

              resolve();
            }, 1500)
          }),
        onRowDelete: oldData =>
          new Promise((resolve, reject) => {
            setTimeout(() => {
              delete_company_source(oldData);

              resolve()
            }, 1000)
          }),
      }}
    />
<p style={{'fontSize':'11px', 'color':'grey'}}> <InfoOutlinedIcon sx={{'paddingTop':'10px'}}/> To get the source name to be entered above - Hover over Source icon corresponding to the news to be blacklisted   </p>
     <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Note archived"
        action={action}
      >
 <Alert onClose={handleClose} severity={type} sx={{ width: '100%' }}>
         {msg}
 </Alert>

  </ Snackbar>

    </>
    )


};






const mapStateToProps = state => ({
    company_source: state.news.company_source,
    load_companysource: state.news.load_companysource


});

export default connect(mapStateToProps,{get_company_source,update_company_source, load_companysource_null, create_company_source, delete_company_source})(CompanySource);

